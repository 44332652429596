import React, {useState, useEffect} from 'react'
import {Message,Tab, Button, Icon, Form,  Modal} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   
import useTexts from '../useTexts'
import {api,baseUrl} from '../../config.jsx'
import FileEditor from "../FileEditor"

// Modal dialog for configuring the rails uploading the files for the rails

const ModalRail=(props)=>
{
    
    const texts=useTexts('lib20Texts','Rails')

    const {children, modalParams, resetModal, modalSubmit}=props

    const {railName}= modalParams[0]
    const panes=[
        { menuItem: texts.leftRail, render: () => <Tab.Pane><EditRail side='left' {...modalParams[0]} resetModal={resetModal} /></Tab.Pane> },
        { menuItem: texts.rightRail, render: () => <Tab.Pane><EditRail side='right' {...modalParams[0]} resetModal={resetModal}/></Tab.Pane> },
    ]

    return (<>
        <Modal.Header as="h3">{texts.modalRailHeader} {railName}</Modal.Header>
        <Modal.Content>
            <Tab panes={panes}/>
        </Modal.Content>
    </>
    )

}

const EditRail=(props)=>
{
    const texts=useTexts('lib20Texts', 'Rails')
    const {side,railPath, setModalParams, resetModal}=props
    const [rail,setRail]=useState({
        type:'gallery',
        files:[]
    })
    const [modified,setModified]=useState(null)
    const [loading,setLoading]=useState(false)


    const {apiInsert,apiDelete, apiGetFile,apiPutFile, apiDeleteFile}=useApi()

    useEffect(()=>{
        const getRail= async()=>{
            
            const data=await apiGetFile(`/kpe20/files/rail/${railPath}/${side}/config.json?date=${Date.now()}`)
                            .catch(()=> console.log('not found'))
            if(data)
            {
                const text=await data.text()
                setRail(JSON.parse(text))
            }
            else
            {
                setRail({
                    type:'gallery',
                    files:[]
                })
                setModified(Date.now())
            }

        }
        getRail()
    },[side])

    useEffect(()=>{
        const putRail=async ()=>
        {
            const newText=JSON.stringify(rail,null,4)
            const filePath=encodeURIComponent(`config.json`)
            const file = new Blob([newText], {type:'application/json'});
            const result=await apiPutFile(`/kpe20/files/rail/${railPath}`,file,'config.json',side)
            if(!result)
                window.alert('something went wrong')
        }
        if(modified)
            putRail()
    },[modified,rail])

    const changeType=(ev,{value})=>
    {
        setRail(r=>({...r,type:value}))
        setModified(Date.now())
    }

    const uploadFile=async (e)=> {
        setLoading(true)
        const myFile = e.target.files[0]
        const result=await apiPutFile(`/kpe20/files/rail/${railPath}`,myFile,myFile.name,side) 
        setLoading(false)
        if(result.success)
        {
            const image= /^image/i.test(result.Data[0].fileMime)
            const html= result.Data[0].fileMime==='text/html'
            const pathC=result.Data[0].key.split('/')
            const file=pathC.pop()
            setRail((r)=>({...r,files:[...r.files,{origName:result.name,fileName:file, image:image,html:html, key:result.Data[0].Key}]}))
            setModified(Date.now())
        }
        else
            alert(result.message)
    }


    const deleteFile=async (ev,{index})=>
    {
        const pathC=rail.files[index].key.split('/')
        const file=pathC.pop()
        const result=await apiDeleteFile(`/kpe20/files/rail/${railPath}`,file,side,'kpe20')
        if(result.success)
        {
            setRail((r)=>({...r,files:r.files.filter((f,i)=>i!==index)}))
            setModified(Date.now())
        }
    }

    const editHtmlFile=async (e,{name})=>
    {
        setModalParams({
            addLevel:
            {
                component: FileEditor,
                getUrl: `/kpe20/files/rail/${railPath}/${side}/${name}`,
                putUrl: `/kpe20/files/rail/${railPath}`,
                prefix: side,
                filename: name,
                language:'html',
                defaultCode:`<p>empty Page</p>\n
                <!--  \n
                      %root% in path specifications may be used to create a path to uploaded files\n
                      e.g. for an uplaoded image: <img src="%root%/imagefile-PPREVIEW400.jpg\n
                -->`,
                mimeType:"text/html",
                size: 'large'

            }
        })  
    }

    return (
        <Form warning>
            <Form.Select
                label={texts.railType}
                options={[
                    {key:'gallery', text: texts.railGallery, value:'gallery'},
                    {key:'html', text: texts.railHtml, value:'html'},
                    {key:'inherit', text: texts.railInherit, value:'inherit'},
                    {key:'off', text: texts.railOff, value:'off'}
                ]}
                value={rail.type}
                onChange={changeType}
            />
            {
                rail.type==='gallery' &&
                <>
                    <Button  htmlFor='imageFile' 
                            type='button' as='label' width={1} loading={loading}>
                            {loading===true ? '' : <>
                            <input type='file' id='imageFile'  accept='image/*'  style={{display: 'none'}} onChange={uploadFile}/>
                                    <Icon name='upload' color='green'/> {texts.railGalleryUpload}</>
                            }
                    </Button>
                    <ul>
                        {
                            rail.files.filter(file=>(file.image)).map((file,Index)=>(
                            <li>
                                <img src={`https://${api}.${baseUrl}/kpe20/files/rail/${railPath}/${side}/${file.fileName}?date=${modified}`} width="250"/> 
                                <Button index={Index} icon="trash" circular basic onClick={deleteFile}/>
                            </li>
                        ))}
                    </ul>
                </>
            }
            {
                rail.type==='html' &&
                <>
                    <Button  htmlFor='htmlFile' 
                        type='button' as='label' width={1} loading={loading}>
                        {loading===true ? '' : <>
                        <input type='file' id='htmlFile'  accept='image/*,.html,.js,.css'  style={{display: 'none'}} onChange={uploadFile}/>
                                <Icon name='upload' color='green'/> {texts.railHtmlUpload}</>
                        }
                    </Button>
                    <ul>
                        <li>index.html <Button basic circular green icon="edit" onClick={editHtmlFile} name="index.html"/>  </li>
                        {
                            
                            rail.files.map((file,Index)=>(
                            <li>
                                {
                                    file.html &&
                                    <Button basic circular green icon="edit" onClick={editHtmlFile} name={file.fileName}/>
                                }
                                {file.fileName} 
                                <Button index={Index} icon="trash" circular basic onClick={deleteFile}/>
                            </li>
                        ))}
                    </ul>
                </>
            }
            <Message  warning>
                {texts.railReload}
            </Message>
            <Form.Button color="green" icon='save' onClick={e=>resetModal()}/>
        </Form>
    )
}


export default ModalRail