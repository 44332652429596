
import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Dropdown} from 'semantic-ui-react'


const ButtonSelect=(props)=>
{
    const {options, onChange, value, name: fieldName, placeholder,multiple,...extraTags}=props

 
    const onClick=(ev,{name})=>
    {
        if(onChange)
        {
            if(multiple)
            {
                if(value.includes(name))
                    onChange(ev,{value:value.filter(el=>el!==name), name: fieldName})
                else
                    onChange(ev,{value:[...value,name], name: fieldName})
            }
            else
            {
                onChange(ev,{value:name, name: fieldName})
            }
        }
    }
    const actOption= options.find(option=>option.value===value)
    const displayedValue= actOption ? 
        (multiple ? '['+actOption.map((op)=>(op.text)).join(',')+']' : actOption.text)
     : placeholder
    return (
    
    <Dropdown
        text={displayedValue }
        floating
        labeled
        className='button'
        compact
        button
        icon='nothing'
        scrolling
        {...extraTags}    
    > 
        <Dropdown.Menu>
            {
                options.map(option=>
                        <Dropdown.Item 
                            name={option.value} 
                            onClick={onClick}

                            key={option.value}
                        >
                        { 
                            multiple ?
                            
                                <span style={
                                    actOption.find(op=>op.value===option.value) ? {backgroundColor:'#e0e1e2'} : {backgroundColor:'initial'}
                                    }>{option.text}
                                </span>
                                :
                                <span>{option.text}</span>
                        }
                        </Dropdown.Item>
                               
                    ) 
            }
        
        </Dropdown.Menu>
    </Dropdown>
    )
}
export default ButtonSelect