import React, {useState, useReducer, useEffect, useRef}  from 'react';
import {Segment, Message, Header} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'

import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { baseUrl, api } from '../config';


const Maintenance=(props)=> {

    const {actions,setActions,setModalParams, functions,achievements, setContainerWidth}=props
    const [Index,setIndex]=useState(null)
    const [timestamp,setTimestamp]=useState(0)
    const texts=useTexts('/actions')
    const {apiDelete, apiUpdate}=useApi()

    const urlBase=`https://${api}.${baseUrl}/kpe20/maintenance`
    useEffect(
        ()=>{setContainerWidth(800)},
    [])
    return(
        <Segment>
            <Header as="h2" content="Maintenance Actions"/>
            <Message warning>"Use with care, as they can create a high system load"</Message>
            <Header as="h3">recreate the Title/Display properties of Database Objects by Object Type</Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/recreate/person`}>Recreate all Persons</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/extern`}>Recreate all external Persons</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/guest`}>Recreate all Guests</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/job`}>Recreate all Jobs</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/group`}>Recreate all Groups (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/ggroup`}>Recreate all Guest Groups (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/list`}>Recreate all Lists (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/dlist`}>Recreate all DLists (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/email`}>Recreate all E-Mails (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/function`}>Recreate all Function/Job Templates (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/achievement`}>Recreate all Achievement Templates (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/event`}>Recreate all Events (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/eventT`}>Recreate all Event Templates (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/eventJobT`}>Recreate all Event Job Templates (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/recreate/location`}>Recreate all Locations (in progress)</a></li>

            </ul>
            <Header as="h3">Rebuild the ObjectOwnership Tree </Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/tree/person`}>Rebuild Persons Tree (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/tree/extern`}>Rebuild External Persons Tree (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/tree/job`}>Rebuild Jobs Tree (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/tree/group`}>Rebuild Group Tree (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/tree/event`}>Rebuild Group Tree (in progress)</a></li>
            </ul>
            <Header as="h3">Rebuild Visibility </Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/visibility/job`}>Related to a Job</a></li>
                <li><a target="_blank" href={`${urlBase}/visibility/list`}>Of Lists</a></li>
                <li><a target="_blank" href={`${urlBase}/visibility/dlist`}>Of DLists</a></li>
                <li><a target="_blank" href={`${urlBase}/visibility/email`}>Of E-Mails</a></li>
                <li><a target="_blank" href={`${urlBase}/visibility/event`}>Of Events (in progress)</a></li>
                <li><a target="_blank" href={`${urlBase}/visibility/eventT`}>Of Event Templates (in progress)</a></li>
            </ul>
            <Header as="h3">Rebuild all Jobs based on the Persons Achievements </Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/achievements/tree`}>Rebuild All Jobs (in progress)</a></li>
            </ul>
            <Header as="h3">Rebuild again the links between Job Templates and Achievements and Checks all Jobs Qualifications (in progress) </Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/requalify`}>Rebuild All Jobs</a></li>
            </ul>
            <Header as="h3">Rebuild all Family Links</Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/family/all`}>Rebuild All Families</a></li>
            </ul>
            <Header as="h3">Rebuild all Dynamic Lists</Header>
            <ul>
                <li><a target="_blank" href={`${urlBase}/rebuildListEntries/all`}>Rebuild All Dynamic Lists for all Members/Externals</a></li>
            </ul>


        </Segment>
    )
}
export default Maintenance
