import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Container,Header,Icon,Button, List, Menu, Segment} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useRoot} from '../lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {api,baseUrl} from '../config' 
import FileEditor from "../lib20/FileEditor"

export const Orgas=(props)=>
{
    const {apiSelect, apiDelete, apiPutFile}= useApi()
    const [configs,setConfigs]=useState([])
    const texts=useTexts('/orgas')
    const [loading,setLoading]=useState(false)
    const [timestamp,setTimestamp]=useState(Date.now())
    const [currentRoot,]=useRoot()
    const {setModalParams}=props

    useEffect(()=>{
        const getConfigs=async ()=>
        {
            const result=await apiSelect('/kpe20/config/admin')
            if(result.success)
                setConfigs(result.files)
        }
        getConfigs()
    },[timestamp])

    const uploadFile=async (e)=> {
        setLoading(true)
        const myFile = e.target.files[0]
        const result=await apiPutFile(`/kpe20/config/admin`,myFile,`${myFile.name}.yaml`,'') 
        setLoading(false)
        if(result.success)
        {
            setTimestamp(Date.now())
        }
        else
            alert(result.message)
    }

    const editConfigFile=async (e,{name})=>
    {
        setModalParams({
            addLevel:
            {
                component: FileEditor,
                getUrl: `/kpe20/config/admin/${name}`,
                putUrl: `/kpe20/config/admin`,
                filename: name+'.yaml',
                language:'yaml',
                defaultCode:"",
                mimeType:"application/yaml",
                size: 'large'

            }
        })  
    }

    const deleteConfig=async (ev,{name})=>
    {
        if(name!==currentRoot)
        {
            const result=await apiDelete(`/kpe20/config/${name}`)
            console.log(result)
            setTimestamp(Date.now())
        }
    }

    return(
        <Container text>
            <List>
                {

                    configs.map(config=>
                    
                        <List.Item>
                            <a href={`https://${api}.${baseUrl}/kpe20/admin/config/${config.UID}`}>{config.AppName} {config.Orga}</a>&nbsp;
                            <Button basic icon='edit' onClick={editConfigFile} name={config.UID}/>
                            {
                                config.UID!==currentRoot &&
                                <Button basic color='red' icon='trash' onClick={deleteConfig} name={config.UID} />
                            }
                        </List.Item>

                    )
                }
                <List.Item>
                    <Button  htmlFor='file2' 
                        type='button' as='label' width={1} loading={loading}>
                        {loading===true ? '' : <>
                        <input type='file' id='file2'  accept='.yaml'  style={{display: 'none'}} onChange={uploadFile}/>
                                <Icon name='upload' color='green'/> Upload Orga Config</>
                        }
                    </Button>
                </List.Item>
            
            </List>
        </Container>
    )
}

export default Orgas