import React,{ useState, useEffect, useRef}  from 'react';
import { Menu,Icon,Segment} from 'semantic-ui-react'
import useTexts from '../useTexts'
import ButtonSelect from './ButtonSelect'
import {useConfig} from '../KPE20context'

const AchievementElement=({value,para,setPara,tag,setTag,oper,setOper,removeEntry,achievements, name, addLevel, logTags})=>
{

    const texts=useTexts('lib20Texts','FilterElement')


    const tagOptions= [
        ...achievements.map(ac=>({value:ac.UID,key:ac.UID,text:ac.Display}))
    ]

  



  
    const handleChange=(ev,{name,value})=>
    {
        if(name==='tag')
        {
            setTag(value)
          //  setOper('')
            setPara('')
        }
    
    }


    return  (        
    <Segment basic compact style={{padding:'0'}} size='small'>
        <ButtonSelect
            name='tag'
            onChange={handleChange}
            value={tag}
            options={tagOptions}
            placeholder={texts.placeholderTag}
        />
        
        {
           /* Object.keys(value).length===0 ||*/!removeEntry ? '' :
                 <Icon name='trash'  size='small' color='red' onClick={()=>removeEntry(name)}/>


        }
      
        
    </Segment>
    
    )
}


export default AchievementElement