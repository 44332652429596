import React,{ useState, useEffect, useRef}  from 'react';
import { Menu,Icon, Segment} from 'semantic-ui-react'
import useTexts from '../useTexts'
import ButtonSelect from './ButtonSelect'
import {useConfig} from '../KPE20context'
import LogicalElement from '../FilterSources/LogicalElement'
import ConditionalElement from './ConditionalElement'




const FilterElement=(props)=>
{
    const texts=useTexts('lib20Texts','FilterElement')

 
   

    const {type,value,onChange,removeEntry,myIndex,achievements,functions,name,index}=props
    const [tag,setTag]= useState() // tags are the combination of tag and oper
    const [oper,setOper]=useState()
    const [para,setPara]=useState()


    let logical= ['and','or','nand','nor','xor'].includes(tag)

    const logTags=[
        { value:'and', key:'and' ,text:texts.lAnd, color:'orange'}, 
        { value:'or', key:'or', text:texts.lOr, color:'green'}, 
        { value:'xor' , key:'xor',text:texts.lXor, color:'red'},
        { value: 'nand', key:'nand',text:texts.lNand, color:'violett'},
        { value: 'nor', key:'nor', text:texts.lNor, color:'grey'}
    ]

   

    const removeEntryFunction=(index)=>
    {
        const newArray=para.filter((el,i)=>(i!==index))
        if(newArray.length>1)
            setPara(newArray)
        else
        {
            const {tag,oper,para}= getTag(newArray[0])
            setTag(tag)
            setOper(oper)
            setPara(para)
        }
    }

    const getTag=(filterObject)=>
    {
        const tag= Object.keys(filterObject)[0]
        const logical= ['and','or','nand','nor','xor'].includes(tag)
        if(logical)
        {
            return {
                tag:tag,
                oper:null,
                para:filterObject[tag], 
                tagLogical:logical
            }
        }
        else if(tag!=='none' && tag!=='all')
        {
            return {
                    tag:tag.substr(0,tag.length-2),
                    oper:tag.substr(-2),
                    para:filterObject[tag],
                    tagLogical:logical
            }
        }
        else
        {
            return {
                tag:tag,
                oper:null,
                para:null,
                tagLogical:false
            }
        }
    }

    useEffect(()=>
    {
        // getter use effect
        if(value && Object.keys(value).length>0)
        {
            const {tag,oper,para,tagLogical}= getTag(value)
            setTag(tag)
            setOper(oper)
            setPara(para)
            logical=tagLogical


        }
        else
        {
            setTag(null)
            setOper(null)
            setPara(null)
            logical=false
        }
      
    },[value])

    useEffect(()=>{

        if(tag==='none' || tag==='all')
        {
            if(JSON.stringify(value)!==JSON.stringify({[tag]:null}))
                onChange({},{value:{[tag]:null},name:name, index: index})
        }
        else if(tag  && para && onChange)
        {
            if(logical)
            {
                if(JSON.stringify(value)!==JSON.stringify({[tag]:para}))

                    onChange({},{value:{[tag]:para},name:name, index,index})
            }
            else
            {
                if(JSON.stringify(value)!==JSON.stringify({[tag+oper]:para}))

                    onChange({},{value:{[tag+oper]:para},name:name, index,index})
            }
        }
    }, [tag,oper,para])



  
    const setSubFilter=(ev,{value,name})=>
    {
        if(name ==-1)
        {
            // add it
            setPara(state=>([...state,value]))
        }
        else
        {
            setPara(state=>state.map((el,Index)=>
            (
                Index===name ? value : el
            )
            ))
        }

       
    }

    const addLevel=(ev,{value})=>
    {
        setTag(value)
        if(logical)
        {
            setPara([{[tag]:para}])    
            setOper(null)
        }
        else
        {
            setOper('')
            setPara([{[`${tag}${oper}`]:para},{}])
        }
    }

      
    return (
    <Segment compact size='small'>
       
       {
        logical ? 
            <LogicalElement
                tag={tag}
                setTag={setTag}
                para={para}
                setPara={setPara}
                type={type}
                setSubFilter={setSubFilter}
                removeEntryFunction={removeEntryFunction}
                functions={functions}
                achievements={achievements}
                setOper={setOper}
                logTags={logTags}
                name={name}
                index={index}
                addLevel={addLevel}
            />
        :
            <ConditionalElement
                tag={tag}
                setTag={setTag}
                oper={oper}
                setOper={setOper}
                para={para}
                setPara={setPara}
                removeEntry={removeEntry}
                functions={functions}
                achievements={achievements}
                value={value}
                name={name}
                index={index}
                type={type}
                logTags={logTags}
                addLevel={addLevel}


            />
    }
   

                  
      
               

    </Segment>
    ) 
}

export default FilterElement