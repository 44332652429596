import React, { useState, useReducer, useEffect, useRef }  from 'react'
import {Segment, Header, Popup, Button} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useWindowDimensions } from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import MyTabulator, {autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import EditAchievement from './EditAchievement'

const Achievements=(props)=> {

const {setModalParams, achievements,  setAchievements, functions, setContainerWidth}=props

    const texts=useTexts('/achievements')
    const [editUID,setEditUid]=useState(null)
    const [timestamp,setTimestamp]=useState(0)

    
    const {height}=useWindowDimensions()

    const cellClick=(cell)=> {

        setEditUid(cell.getData().UID)
        setTimestamp(Date.now())
    }

    const addAchievement=(ev)=>
    {
        setModalParams(
            {
                addLevel:{
                    component: EditAchievement,
                    createNew:true,
                    achievements,
                }
            }
        )
    }
    
    useEffect(()=> {
        // have to place this call into an useEffect, as the react status is no availlable in a callback function of tabulator
        if(editUID)
        {
            setModalParams({
                addLevel:{
                    component:EditAchievement,
                    editUID: editUID,
                    achievements:achievements,
                    setAchievements:setAchievements,
                    functions:functions
                }
            })
        }
        
    },[editUID,timestamp])

    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {title:  texts.acsTitle, field: 'Display', width: 400,  headerFilter:"input"},
        {title:  texts.renewal, field: 'renewal',  width:200, headerFilter:"number"},   
    ]

    useEffect(
        ()=>{setContainerWidth(800)},
    [])

    return (
        <Segment>
            <Header as="h2">{texts.acsHeader}</Header>
            <Popup
                    trigger={<Button 
                        circular 
                        icon='plus' 
                        basic
                        color='green' 
                        onClick={addAchievement} 
                        size='mini'/>}
                    content={texts.addAchievement}
                    style={{ position: 'absolute', right:'0px'}}
                    position='left center'
                    offset={[-40, 0]}
                />
            <MyTabulator
                index='UID'
                columns={listColumns}
                headerFilterPlaceholder={texts.headerFilterPlaceholder}
                data={achievements}
                onClick={cellClick}
                compact="very"
                layout="fitDataStretch"
                striped
               
                height={Math.max(height-250,500)}      

            />
        </Segment>
    )
}

export default Achievements
