import React,{useEffect, useRef,useState} from 'react';

const useClipboard=({onChange=null,filter=null,alwaysUpdate=false, copyAlert=null, browserSupport=0})=>
{
    const { ClipboardItem } = window;
    // onchange is the function called, when the clipboard changes
    // filter is a function that will filter events depending on teh clipboard content
    const [clip,setClip]=useState(null)
    const [supported,setSupported]=useState(false)
    const updated=useRef(false)

    const contentChanged= async (e)=>
    {
        try {
            if(navigator.clipboard.readText!==undefined)
            {
                const clipboardContent = await navigator.clipboard.readText()
                .catch(e=>console.log(e))
                console.log(clipboardContent)
                let data
    
                try
                {
                    data= JSON.parse(clipboardContent)
                }
                catch(e)
                {
                    console.log('clipboard json parse error',e)
                    setClip(null)
                }
                if(!filter ||  filter(data) )
                {
                    setClip(data)
                    if(onChange)
                    {
                        onChange(data)
                    }

                }
                else
                {
                    setClip(null)
                }
            }
            else
            {
                setClip(null)
            }

          } catch (err) {
            //setClip(null)
            const clipboardContent = await navigator.clipboard.readText();
            console.log(clipboardContent)
            console.error(err);
          }

    }



        
        

    useEffect(()=>
    {/*
     navigator.clipboard.addEventListener(
            "contentchanged", contentChanged); 
            return () => { window.removeEventListener("contentchanged", contentChanged) }*/

    },[])

    const changeClip= (data)=>
    {
        setClip(data)
        const myData= typeof data==='string' ? data : JSON.stringify(data,0,4)
        try{
            if(typeof ClipboardItem && navigator.clipboard.write) 
            {
                // NOTE: Safari locks down the clipboard API to only work when triggered
                //   by a direct user interaction. You can't use it async in a promise.
                //   But! You can wrap the promise in a ClipboardItem, and give that to
                //   the clipboard API.
                //   Found this on https://developer.apple.com/forums/thread/691873

                // Now, we can write to the clipboard in Safari


                const clipboardItem = new ClipboardItem({
                "text/plain": new Promise(res=>{
                    const dataText=myData
                    res(new Blob([dataText],{type : 'text/plain'}))
                })
                
                })
                navigator.clipboard.write([clipboardItem])
                    .catch(e=>window.alert('something went wrong with writing to the clipboard'))
                    .then(()=>window.alert(copyAlert?  copyAlert : 'Data copied')   )
               

            }
            else if(navigator.clipboard.writeText)
            {
                window.alert(copyAlert?  copyAlert : 'Data copied')   
                navigator.clipboard.writeText(myData)
                .catch(e=>window.alert('something went wrong with writing to the clipboard'))
                .then(()=>window.alert(copyAlert?  copyAlert : 'Data copied')   )

            }
            else
            {
                window.alert(browserSupport ? browserSupport :'your bowser does not support the system clipboard')
            }
        }
        catch(e)
        {
            window.alert('you have not allowed in your browser to use this feature')
        }
            
       
    
    }

    const updateClip=()=>
    {
        contentChanged()    
    }

    return ([clip,changeClip,contentChanged,updateClip /* for legacy*/, supported])
}

export default useClipboard
