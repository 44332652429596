import React, {useState, useEffect, useRef}  from 'react'
import {Dropdown} from 'semantic-ui-react'

const DropdownModalNew=(props)=>
{
    const {text,setSidebarVisible,component,setModalParams,icon,modalProps,myKey}=props
    const openModalNew=(ev ) => {
       setModalParams({addLevel:{component:component,createNew:true,...modalProps}})
       setSidebarVisible(false)
    }

    return (
        <Dropdown.Item
            onClick={openModalNew}
            key={myKey}
            text={text}
            icon={icon}
            
        />

        
       
    )

}

export default DropdownModalNew