import { Form, Header, Segment, Label, Icon } from "semantic-ui-react";
import { SearchSelect } from "@wollo-lib/wollo-react";
import React, { useEffect, useMemo } from "react";
import { baseUrl, api } from '../config.jsx'
// we have to use the Classiceditor, not the BalloonEditor, as the balloon editor seems not to receal its toolbar in a Modal 
import { CKEditorUpload as TextEditor } from '../lib20/CKeditor/CKInlineEditor'
import FilterElement from "../lib20/FilterSources/FilterElement.jsx";
import { useConfig } from "../lib20/KPE20context.jsx";



// https://www.storyblok.com/tp/react-dynamic-component-from-json
//https://codesandbox.io/s/semantic-ui-react-forked-4ybmh6?file=/index.js


const example =
{
    Segment: {
        content: [
            {
                "Form.Input": {
                    paras: {
                        placeholder: "topic",
                        label: "topic",
                        name: "topic"

                    },
                    checks: ['mandatory']
                }
            },
            {
                "Form.Editor": {
                    paras: {
                        name: "text"
                    },
                    checks: ['mandatory']
                }
            }
        ]
    }
}

const translate = (value, action) => {
    return value ? action.translate[value] ? action.translate[value] : value : null
}


const MySegment = ({ content, paras, myKey, props }) => {
    return <Segment key={myKey} {...paras}>
        {
            content.map((el, Index) => {
                return ActionUI({ ...props, UIaction: el, myKey: myKey + 'S' + Index })
            }
            )
        }
    </Segment>
}


const MyFormGroup = ({ content, paras, myKey, props }) => {
    return <Form.Group key={myKey} {...paras}>
        {
            content.map((el, Index) => {
                return ActionUI({ ...props, UIaction: el, myKey: myKey + 'FG' + Index })
            }
            )
        }
    </Form.Group>
}

const MyForm = ({ content, paras, myKey, props }) => {
    return <Form key={myKey} {...paras}>
        {
            content.map((el, Index) => {
                return ActionUI({ ...props, UIaction: el, myKey: myKey + 'F' + Index })
            }
            )
        }
    </Form>
}





const MyHeader = ({  props,  myKey, paras }) => {
    const { action}=props
    const {as ,content, ...extraProps}=paras
    return <Header
        content={translate(content, action)}
        key={myKey}
        as={as}
        {...extraProps}
    />
}


const MyInput = ({  myKey,props, paras, checks }) => {
    const { action, data, onChange, functions, achievements,errors, addUIchecks  } = props
    const {placeholder = null, label = null,name,...extraProps}=paras
   useEffect(()=>{
        if(checks)
            addUIchecks(name,checks)
    },[checks])

    
    return <Form.Input
        onChange={onChange}
        name={name}
        value={data[name]}
        placeholder={translate(placeholder, action)}
        label={translate(label, action)}
        key={myKey}
        {...extraProps}
        error={errors[name]}

    />
}

const MyToggle = ({  myKey,props, paras, checks }) => {
    const { action, data, onChange, functions, achievements,errors, addUIchecks  } = props
    const {placeholder = null, label = null,name,...extraProps}=paras

    const myOnChange=(ev,{checked,name})=>
    {
        if(JSON.stringify(checked)!==JSON.stringify(data[name]) && onChange)
        {
            onChange(ev,{name,value:checked})
        }
    }
    
    return <Form.Checkbox
        onChange={myOnChange}
        name={name}
        checked={data[name]}
        placeholder={translate(placeholder, action)}
        label={translate(label, action)}
        key={myKey}
        {...extraProps}
        error={errors[name]}

    />
}


const MySelect = ( {myKey, paras, props, checks }) => {
    const { action, data, onChange, functions, achievements,errors, addUIchecks } = props
    const {name,placeholder = null, label = null, ...extraProps}=paras
 
    useEffect(()=>{
        if(checks)
            addUIchecks(name,checks)
    },[checks])
 
    return <Form.Select
        onChange={onChange}
        name={name}
        value={data[name]}
        placeholder={translate(placeholder, action)}
        label={translate(label, action)}
        key={myKey}
        {...extraProps}
        error={errors[name]}

    />
}

const MyEditor = ({paras,  props }) => {


    const { myKey, checks, action, data, onChange, functions, achievements,errors, addUIchecks} = props
    const {  label = null,name, ...extraProps}=paras
    const config = useConfig()
    const EditorPlaceholder = useMemo(() => (
        config ?
            [...Object.keys(config.EmailGenderPlaceholder), ...Object.values(config.EmailPlaceholder), ...Object.values(config.EmailActionPlaceholder)] : []
    ), [config])

    useEffect(()=>{
        if(checks)
            addUIchecks(name,checks)
    },[checks])

    return (

        <Form.Field
            error={errors.text}
            control={TextEditor}
            onChange={onChange}
            name={name}
            value={data[name] ? data[name] : '<p>empty</p>'}
            label={translate(label, action)}
            uploadUrl={'https://' + api + '.' + baseUrl + '/kpe20/files/editor/365/' + action.UID}
            placeholders={EditorPlaceholder}
            key={myKey}
            className={'UIEditor' + myKey}
            onInit={(editor) => {

            }

            }

        />
    )
}

const MySearchList = ({paras,checks,myKey,  props,labelProps={}  }) => {
    const { action, data, onChange,errors, addUIchecks  } = props
    const { name,label = null,placeholder=null,...extraProps} =paras 
    // a search component which adds persons to a list

    const addEntry=(ev,{result,value})=>
    {
        if(data[name]&& Array.isArray(data[name]) && value)
        {
            onChange(ev,{name:name,value:[...data[name],{value:result.value,title:result.title}]})
        }
        else if(value)
        {
            onChange(ev,{name:name,value:[{value:result.value,title:result.title}]})
        }

        
    }

    const deleteEntry=(ev,{value})=>
    {
        onChange(ev,{name:name,value:data[name].filter(el=>el.value!==value)})
    }

    useEffect(()=>{
        if(checks)
            addUIchecks(name,checks)
    },[checks])


    return (<Segment>
        <Form.Field
            {...extraProps}
            control={SearchSelect}
            onChange={addEntry}
            autoSelect
            autoClear
            label={translate(label, action)}
            placeholder={translate(placeholder, action)}
            name={name}
            key={myKey}
            error={errors[name]}
            SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
        />
        <Segment secondary>
        {
            data[name]?.map((entry,Index)=>(
                <Label
                    size='small'
                    key={myKey+entry.value}
                >
                    {entry.title}
                    <Icon  name='close' value={entry.value} onClick={deleteEntry}/>
                 </Label>
                
            ))
        }
        </Segment>
   </Segment>)

}


const MyFilter = ({myKey, props, paras }) => {
    const { action, data, onChange, functions, achievements } = props
    const {name, label = null}=paras
    // prevent infinete update loop
    const myOnChange=(ev,{value,name})=>
    {
        if(JSON.stringify(value)!==JSON.stringify(data[name]) && onChange)
        {
            onChange(ev,{name,value})
        }
    }

    useEffect(()=>
    {
        if(!data[name])
        {
            onChange({},{name,value: { none: null }})
        }
    },[data[name]])

    return (     
        <Form.Field
            control={FilterElement}
            onChange={myOnChange}
            name={name}
            value={data[name] ? data[name] : { none: null }}
            label={translate(label, action)}
            functions={functions}
            achievements={achievements}
            type='job'
            key={myKey}
        />

    )
}



const ActionUI = (props) => {
    const { UIaction, action, data, onChange, functions, achievements,errors, addUIchecks,myKey = 'UIRoot' } = props
    if (UIaction) {
        const components = {
            Segment: MySegment,
            Header: MyHeader,
            Form: MyForm,
            'Form.Input': MyInput,
            'Form.Group': MyFormGroup,
            'Form.Select': MySelect,
            'Form.Editor': MyEditor,
            'Form.SearchList': MySearchList,
            'Form.Filter': MyFilter,
            'Form.Checkbox': MyToggle,
        }


        const [[component, { content, paras, checks}]] = Object.entries(UIaction)

        console.log(UIaction)

        const getComponent = () => {
            return components[component] ? components[component] : component
        }
        const tComponent = getComponent()
        return React.createElement(tComponent, {paras,checks, content,addUIchecks, myKey, props, key: myKey }, content)
    }
    else
        return ''

}

export default ActionUI