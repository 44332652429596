import React from 'react'
import { useConfig,useQueueStatus} from './KPE20context'
import { Segment, SegmentGroup} from 'semantic-ui-react'
import useTexts from './useTexts'


export const  SystemMessage=(props)=>
{
    const config=useConfig()
    const texts=useTexts('lib20Texts')
    const processing = useQueueStatus()
    return(<Segment.Group style={{marginTop:0, border:0}}>
        {
            config.maintenance ? 
            <Segment inverted color='red' ><p>{config.maintenanceMessage}</p></Segment>
            : ''
            
        }
        {
            processing > config.processingMessageTreshold &&
            <Segment inverted color='yellow' attached="top"><p>{texts.largeQueue}</p></Segment>
        }
        {
            config.userMessage &&
            <Segment inverted color='green' attached="top"><p>{config.userMessage}</p></Segment>
        }
    </Segment.Group>)

}

export default SystemMessage