import React,{ useState, useEffect}   from 'react'
import {Modal} from 'semantic-ui-react'
import {Segment,Header,List} from 'semantic-ui-react'
import {useConfig} from '../KPE20context'
import { useLocation } from 'react-router-dom'

const Help=() => {
    
    
    const config=useConfig()
    const location=useLocation()
    const [help,setHelp]= useState({})


    // checkif  in config exists an regex in helpLinks, which matches the current path
    // if yes, add an extra menu Help, if it does not exist yet
    useEffect(()=>{
        const newState={}
        Object.entries(config.helpLinks).forEach(([regex,categories])=>{
            if(location.pathname.match(regex))
            {
                // go through all categories and create them or extend them im newState
                Object.entries(categories).forEach(([category,links])=>{
                    if(newState[category])
                    {
                        const thisCategory=newState[category]
                        newState[category]=[...thisCategory,...links.filter(link=>!thisCategory.find(({title})=>title=== link.title))]
                        
                    }
                    else
                    {
                        newState[category]=links
                    }
                })
                return newState

                
            }
        })
        setHelp(newState)
        

    },[config,location])


    return (
        <Modal.Content >
            <Segment>
                <div 
                    dangerouslySetInnerHTML={{__html: config.helpText}}
                />
          
      
                <Header as="h3">
                        <a href={config.helpLinks.main.url} target='_blank' >{config.helpLinks.main.title}</a>
                </Header>
           </Segment>
           { Object.keys(help).length===0 ?'' :
                Object.entries(help).map(([category,links])=>(
                    links && Array.isArray(links) && links.length>0 ?
                    <>
                        <Header as="h3" content={category}/>
                        <List bulleted>
                        {

                            links.map(({title,url})=>(
                                <List.Item>
                                    <Header as="h3">
                                        <a href={url} target='_blank' >{title}</a>
                                    </Header>
                                </List.Item>    

                            ))
                        }
                        </List>
                    </>
                    : ''
                ))
                
           }
           

        </Modal.Content>
    )
}
export default Help