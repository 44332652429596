import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Container,Header,Form,Segment,Modal,Button} from 'semantic-ui-react'
import useTexts from './useTexts'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {useRoot, useFakeUser} from './KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {useHistory} from 'react-router-dom'

const FakeUser=(props)=>
{
    const {children, modalParams, resetModal, modalSubmit}=props

    const texts=useTexts('lib20Texts','fakeUser')
    const [root]=useRoot()
    const {fakeUser,setFakeUser}=useFakeUser()
    const [userUID,setUserUID]=useState(null)
    const [user,setUser]=useState(null)
    const {apiSelect,apiInsert}=useApi()
    const history=useHistory()
    
   

    const handleChange=(ev,{value,result})=>
    {
        setUser(value)
        setUserUID(result.UIDuser)
    }

    modalSubmit.current=async ()=> 
    {
        setFakeUser(userUID)
        history.push('/')
        resetModal()
    }

    return(<>
            

                <Modal.Header>{texts.fakeUserTitle}</Modal.Header>
                
                <Modal.Content>
                    <Modal.Description>
                        {texts.fakeUserExplanation}
                    </Modal.Description>

                    <SearchSelect
                    
                        label={texts.userLabel}
                        placeholder={texts.userPlaceholder}
                        value={user}
                        onChange={handleChange}
                        api = "kpe20"
                        SuggestUrl={'/kpe20/user/SearchData'}
                        Value2TitleUrl={'/kpe20/Value2Title?types='+JSON.stringify(['person','extern'])}
                        autoSelect
                        name='users'

                    />
                   
                </Modal.Content>

           
            {children}
        </>
    )
}
 export default FakeUser