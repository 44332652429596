import {useEffect, useState} from 'react'
import { app } from '../../config'
import {useConfig, useSetConfig} from '../KPE20context'


import {Divider, Header, Icon,Segment, Sidebar, List} from 'semantic-ui-react'

import {useApi} from '@wollo-lib/kpe-context'
import useTexts from '../useTexts'
import useChangelog from './useChangelog'
import { compareVersions } from 'compare-versions';


const VersionSidebar = (props) => {

    const texts = useTexts('lib20Texts','Changelog')
    const {visible, setVisible, logs, onHide, headerText, setNewV} = props

    return(
        <Sidebar 
            as={Segment}
            animation='overlay'
            visible={visible} 
            onHide={onHide}
            direction='right'
            inverted
            vertical
            style={{padding:'15px'}}
            className='version'
        > 
            <Segment className='headerSegment' style={{display:'flex',height:'50px'}}>
                <Header content={headerText} style={{width:'100%'}}/>
                <Icon name='close' onClick={onHide}/>
            </Segment>
                <List>
                    {logs.map((v,index) => (<>
                        <List.Item key={v.version + v.date}>
                            <Header as="h3">{`${texts.version} ${v.version}`}</Header>
                            <Header as="h4"> {v.date}</Header>
                            <List bulleted>
                                {v.content.map((c,i) => (
                                    <List.Item>{c}</List.Item>
                                ))}
                            </List>
                        </List.Item>
                        {index !== (logs.length - 1) ? <Divider/> : null}
                    </>))}
                </List>
        </Sidebar>

    )

}



const NewVersion = (props) => {
    const config = useConfig()
    const setConfig = useSetConfig()
    const {apiUpdate} = useApi()
    const texts = useTexts('lib20Texts','Changelog')

    const userVersion = config.orgaUser.Data.version &&  config.orgaUser.Data.version[app] ? config.orgaUser.Data.version[app] : null
    const [newV, setNewV] = useState([])
    const [visible, setVisible] = useState(false)

    const changelogs=useChangelog()

    useEffect(() => {
        if(changelogs && changelogs.length>0)
        if (config.orgaUser.Data.version && config.orgaUser.Data.version[app]) {
            
            setNewV(changelogs.filter((change)=>compareVersions(change.version,config.orgaUser.Data.version[app])>0))
        }
        else {
            setNewV(changelogs)
        }
    },[config.requiredFrontendVersion, changelogs])

    useEffect(() => {
        if (newV.length > 0) {
            setVisible(true) 
        }

        if (config.orgaUser.Data.version?.db === config.requiredFrontendVersion) {
            setVisible(false) 
        }
    },[newV])


    const onHide = async () => {
        let versions = config.orgaUser.Data.version
        versions = {...versions, [app]:config.requiredFrontendVersion}

        const result = await apiUpdate('/kpe20/person/'+config.orgaUser.UID,{version:versions})

        setConfig({...config, 
            orgaUser:{...config.orgaUser, 
                Data:{...config.orgaUser.Data, 
                    version:versions
                }
            }
        })

        if (result.success) {
            setVisible(false)
        }
    }

    return (
        <VersionSidebar 
            visible={visible} 
            logs={newV} 
            onHide={onHide} 
            headerText={texts.newVersionHeader} 
        />
    )
}

const ChangeLog = (props) => {

    const {visible, setVisible} = props
    const changeLog=useChangelog()

    const texts = useTexts('lib20Texts','Changelog')

    const onHide = () => {
        setVisible(false)
    }

    return(
        <VersionSidebar visible={visible} logs={changeLog} onHide={onHide}  setNewV={null} headerText={texts.versionHistory} />
    )
}

export default ChangeLog
export {NewVersion}