import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Popup,Header,Form,Segment,Modal,Button} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useConfig} from '../lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import FilterElement from '../lib20/FilterSources/FilterElement'
import useClipboard from '../lib20/useClipboard'

const EditAchievement=(props)=> {

    const texts=useTexts('/achievements')
    const {children, resetModal, modalSubmit, modalParams}=props
    const {createNew,editUID,achievements, setAchievements,functions}=modalParams[0]
    const [achievement,setAchievement]=useState({authorized:{}})

    const config=useConfig()
    const {apiDelete, apiInsert}=useApi()

    const stageOptions=config.Stage.map((s,Index)=>({text:s, value:Index,key:Index}))

    const [clip,setClip,updateClip]=useClipboard({
        alwaysUpdate:false,
        filter:(d)=>(d && d.type==='filter'),
        copyAlert:texts.clipboardCopyAlert,
        browserSupport: texts.clipboardNotAvaillable
    })



    modalSubmit.current=async ()=> {
        const result= await saveAchievement(achievement)
        if(result.success)
            resetModal()
    }

    const saveAchievement=async (data, Index)=> {

        if(!createNew) {
            return  await apiInsert('/kpe20/achievementTemplate/',{...data,UID:editUID})
            //setAchievements(acs=>acs.map((ac,i)=>(i===Index?  result.result: ac)))
        }
        else {
            return await apiInsert('/kpe20/achievementTemplate/',data)
        }
    }

    const deleteAchievement=async()=>
    {
        if(window.confirm(texts.confirmDelete))
        {
            apiDelete('/kpe20/achievementTemplate/'+editUID)
            resetModal()
        }
    }

    useEffect(()=> {
        if(editUID && achievements && !createNew) {

            setAchievement(achievements.find(el=>el.UID===editUID).Data)
        }

    },[editUID, achievements])


    const flip=(ev,{name})=> {

        if(achievement[name])
            setAchievement(ac=>({...ac,[name]:false}))
        else
            setAchievement(ac=>({...ac,[name]:true}))
    }

    const onChange=(ev,{name,value})=> {
        if(name!=='renewal')
            setAchievement(ac=>({...ac, [name]:value}))
        else
            setAchievement(ac=>({...ac,renewal:Math.round(365.25*value)}))


    }

    const copyFilter=(ev,{index})=>
    {
        setClip({
            type: 'filter',
            data: achievement.authorized
        })
    }

    
    const pasteFilter=()=>
    {
        updateClip()
        if(clip && clip.type && clip.type==='filter')
        {
            const data=clip.data
            setAchievement(ac=>({...ac, authorized:data}))
        }
        else
        {
            window.alert(texts.clipboardPasteError)
        }
    }



    return (<>
        <Modal.Header as='h3' content={editUID ? texts.acHeaderNew : `${texts.acHeader} ${achievement.title}`}/>
        <Modal.Content scrolling onMouseOver={()=>updateClip()}>
            <Form>
            { !createNew &&
                <Button 
                    icon='trash'
                    content={texts.deleteAchievement}
                    onClick={deleteAchievement}
                    color='red'
                />    
            }
                <Form.Input
                    label={texts.acName}
                    placeholder={texts.acName}
                    name='title'
                    onChange={onChange}
                    value={achievement.title}
                />

                <Form.Input
                    label={texts.acSortIndex}
                    placeholder={texts.acSortIndex}
                    name='sortIndex'
                    onChange={onChange}
                    value={achievement.sortIndex}
                />
            {/* brauchen wir erstmal nicht
                <Form.Field
                    control={FilterElement}
                    label={texts.applicable}
                    onChange={onChange}
                    name='applicable'
                    type='job'
                    value={achievement.applicable ? achievement.applicable :{}}
                    functions={functions}
                    achievements={achievements}
                />
            */}
                <Form.Group>
                    <span>
                        {
                            ( (clip && clip.type && clip.type==='filter') ||  (window.ontouchstart !== undefined)) &&
                            <Popup
                                trigger={
                                <Form.Button 
                                    icon='paste' 
                                    color='teal' 
                                    onClick={pasteFilter} 
                                    size='mini'
                                    circular 
                                    basic
                                />}
                                content={texts.clipboardPaste}
                            />
                        }
                        
                        <Popup
                            trigger={
                                    <Button
                                        icon='copy'
                                        color='blue'
                                        size='mini'
                                        onClick={copyFilter}
                                        circular
                                        basic
                                    />}
                            content={texts.clipboardCopy}
                        />
                    </span>
                     <Form.Field
                        control={FilterElement}
                        label={texts.authorized}
                        onChange={onChange}
                        name='authorized'
                        type='job'
                        value={achievement.authorized ? achievement.authorized :{}}
                        functions={functions}
                        achievements={achievements}
                    />
                </Form.Group>
               
                <Form.Checkbox
                    label={texts.multiple}
                    onClick={flip}
                    toggle
                    name='multiple'
                    checked={achievement.multiple}
                />
                {
                    !achievement.multiple ? '' :
                    <Form.Input
                        label={texts.renewal}
                        placeholder={texts.renewal}
                        name='renewal'
                        onChange={onChange}
                        value={Math.round(achievement.renewal/365.25)}
                        type='number'
                    />
                }
            </Form>
            
        </Modal.Content>
        {children}
    </>)
}
export default EditAchievement