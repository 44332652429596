import React,{ useState, useEffect, useRef}  from 'react';
import {Button,Segment} from 'semantic-ui-react'
import useTexts from '../useTexts'
import ButtonSelect from './ButtonSelect'
import {useConfig} from '../KPE20context'
import FilterElement from './FilterElement';

const LogicalElement=({para,setPara,tag,setTag,type,setSubFilter,addLevel,logTags,removeEntryFunction,functions,achievements})=>
{

    const texts=useTexts('lib20Texts','FilterElement')


    const handleLogical=(ev,{name,value})=>
    {
        if(name==='tag')
        {
            setTag(value)
        }
    }

    const addEntry=(ev)=>
    {
        setPara(old=>
            {
                
            
                    return  [...old,{}]
            
            })
    }

    const last=para[para.length-1]
    const logTag=logTags.find(el=>tag===el.value)

    return  (
    <Segment.Group horizontal compact size='small' style={{margin:'0px'}} >
        

            <Segment.Group piled compact size='small' style={{margin:'0px'}}>
        
            {
                para.map((cond,Index)=>(

                            <FilterElement
                                onChange={(ev,{value,name})=>setSubFilter(ev,{value:value,name:name})}
                                type={type}
                                value={cond}
                                name={Index}
                                removeEntry={removeEntryFunction}
                                functions={functions}
                                achievements={achievements}
                                myIndex={Index}
                                key={Index}
                                />
                            
        

                    ))
                

            }



        </Segment.Group>
        <Segment compact size='small' color={logTag ? logTag.color : 'black'} inverted>
      
            <div>
                <ButtonSelect
                    name='tag'
                    onChange={handleLogical}
                    value={tag}
                    options={logTags}
                    placeholder={texts.placeholderTag}
                    className=''
                />
            </div>
            <div>
                <ButtonSelect
                    name='tag'
                    onChange={addLevel}
                    options={logTags}
                    placeholder={texts.placeholderTag}
                    className=''
                />
            </div>
            {
                !last || JSON.stringify(last)==='{}' ? '' :
                <div>
                    <Button
                        icon='plus'
                        size='mini'
                        circular
                        basic
                        color='green'
                        inverted
                        onClick={addEntry}
                    />
                </div>
            }
        </Segment>



</Segment.Group>
    )
}

export default LogicalElement