import {useEffect,useState,useRef} from 'react'

import { useLangTexts } from './KPE20context'
import { contextTexts } from './lib20Texts'
import {useApi} from '@wollo-lib/kpe-context'
import { app } from '../config'

const useTexts=(path, subPath=null)=>
{
    // returning a ref makes the text exist even in callback outside of react (e.g. tabulator.js)
    const myTexts=useRef({})

   // const config = useConfig()

   // const {apiGetFile}=useApi()

    const langTexts=useLangTexts()
    console.log(app)
    const textsDefault=window[`${app}Texts`] ?? {}
    textsDefault.lib20Texts=contextTexts
   

   
    myTexts.current={...textsDefault.General,...langTexts.General}
    if(Array.isArray(path))
    {
        path.forEach(p=>
        {
           
            myTexts.current={...myTexts.current,...textsDefault[p],...langTexts[p]}
        })
    }
    else if(typeof path==='string')
    {
        const pathText=langTexts[path] ?? {}
        const defaultText=textsDefault[path] ?? {}
        if(subPath)
        {
            myTexts.current={...myTexts.current,...defaultText[subPath],...pathText[subPath]}
        }
        else
        {
            myTexts.current={...myTexts.current,...textsDefault[path],...pathText}
        }
    }
  


    return myTexts.current
}
export default useTexts
