import {useEffect,useState,startTransition} from 'react';
import useTexts from '../useTexts'
import {Popup,Loader, Label, Button} from 'semantic-ui-react'
import {useWsStatus, useQueueStatus} from '../KPE20context'


const ConnectionStatus=(props)=>
{
    const {className} = props
    const [color,setColor]=useState('red')
    const texts=useTexts('lib20Texts','Menu')
    const wsStatus= useWsStatus()
    const processing = useQueueStatus()
    useEffect(()=>{
        let myColor='red'
        if(wsStatus=== 'pong' ||  wsStatus=== 'ping' )
            myColor='green'
        else if((wsStatus=== 'reset' || wsStatus=== 'open' ) && color!=='orange') 
            myColor='orange'
        if(color!==myColor)
        {
            startTransition(()=>{
                setColor(myColor)
            })
        }
    },[wsStatus])

    return (  <>                 
        <Popup
            trigger={<Label 
                circular
                empty
                className={className}
                color={color} 
            />}
            content={texts.connectionStatus}
        />
        {
            processing >0 ?
            <Popup
                trigger={
                    <div>
                        <Button circular content={processing} color="red"/>
                    </div>
                }
                content={texts.queueIsProcessing}
            />

            :''
        }
    </>)


}

export default ConnectionStatus