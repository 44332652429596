import React,{ useState, useEffect, useRef, Children }  from 'react';
import {Modal,Form,Container} from 'semantic-ui-react'
import useTexts from './useTexts'
import {useConfig, useRoot} from './KPE20context'
import {useApi} from '@wollo-lib/kpe-context'
import {useHistory} from 'react-router-dom'

const SelectOrga=(props)=>
{
    const {children, modalParams, resetModal, modalSubmit}=props
    const [orgas,setOrgas]=useState([])
    const [root,setRoot]=useRoot()
    const [orga,setOrga]= useState(root)
    const history=useHistory()
    const config=useConfig()
    const {apiUpdate}=useApi()
    const texts=useTexts('lib20Texts','SelectOrga')
    useEffect(()=>{
        const getOrgas=async()=>
        {
            const myOrgas=await apiUpdate('/kpe20/orga',config.orgaUser.userOrgas,'kpe20')
            if(myOrgas.success)
                setOrgas(myOrgas.result)
        }
        getOrgas()
    },[])
    const onChange=(ev,{value})=>
    {
        setOrga(value)
    }


    modalSubmit.current=async ()=> 
    {
        setRoot(orga)
        history.push('/')
        resetModal()
    }

    return (<>
            
                <Modal.Header as="h2" content={texts.headerOrga}/>
                <Modal.Content>
                    <Form>
                        <Form.Select
                            placeholder={texts.orgaLable}
                            label={texts.orgaLable}
                            options={orgas.map(o=>({flag:o.flagCode,text:`${o.Title} ${o.Display}`,value:o.UID}))}
                            value={root}
                            onChange={onChange}
                        />

                    </Form>
                </Modal.Content>

            {children}
        </>
    )

}
export default SelectOrga