import React, {useState, useReducer, useEffect, useRef}  from 'react';
import {Container, Header} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'

import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import MyTabulator, {autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import EditAction from './EditAction';
import {useWindowDimensions } from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'


const Actions=(props)=> {

    const {actions,setActions,setModalParams, functions,achievements}=props
    const [Index,setIndex]=useState(null)
    const [timestamp,setTimestamp]=useState(0)
    const texts=useTexts('/actions')
    const {apiDelete, apiUpdate}=useApi()

    

    useEffect(()=> {
        // have to place this call into an useEffect, as the react status is no availlable in a callback function of tabulator
        if(Index!==null)
        {
            setModalParams({
                addLevel:{
                    component:EditAction,
                    aIndex: Index,
                    actions: actions,
                    setActions:setActions,
                    functions:functions,
                    achievements:achievements,
                    size:'medium'
                }
            })
        }
        else
            setIndex(null)
    },[Index, timestamp])
    
    const {height}=useWindowDimensions()

    const cellClick=(cell)=> {
        const row=cell.getRow()
        const field=cell.getColumn().getField()
        if(field!=='UID')
        {
            setIndex(row.getPosition()-1)
            setTimestamp(Date.now())
        }
        else
        {
            const data=cell.getData()
            if(window.confirm(`Do you really want to trigger the restart action of this bot: ${data.Display}?`))
            {
                apiUpdate(`/kpe20/actionTemplate/restart/${data.UID}`)
            }
        }
    }

    const rowDelete=(e,cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        if(window.confirm(texts.confirmDelete))
        {
            apiDelete('/kpe20/actionTemplate/'+rowData.UID)
        }

    }   

    var restartIcon = function(cell, formatterParams){ //plain text value
        return `<i  class="redo  icon"></i>`;
    };

    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
      //  {formatter:"buttonCross", width:30, hozAlign:"center", cellClick:rowDelete,frozen:true},
        {title:  texts.Display, field: 'Display', width: 300,  headerFilter:"input"},
        { title:  texts.Action, field: 'action', width: 200,  headerFilter:"input"},
        { title:  texts.Triggers, field: 'triggers', width: 200,  headerFilter:"input"},
        {title: texts.admin, field: 'onlyAdmin', width:150, formatter:"tickCross"},
        {title:'restart',field:'UID',formatter:restartIcon, width:20}
    ]

    
    return(
        <Container>
            <Header as="h2">{texts.fsHeader}</Header>
            <MyTabulator
                index='UID'
                columns={listColumns}
                headerFilterPlaceholder={texts.headerFilterPlaceholder}
                data={actions.map(a=>({...a,action:a.Data.action,triggers:JSON.stringify(a.Data.triggers), onlyAdmin:a.Data.onlyAdmin}))}
                onClick={cellClick}
                compact="very"
                striped
                layout="fitColumns"  
                replaceMode={true}
                height={Math.max(height-250,500)} 

            />
        </Container>
    )
}

export default Actions