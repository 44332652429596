import React, {useState, useEffect,useRef,lazy, startTransition} from 'react'
import { useApi } from '@wollo-lib/kpe-context';
import { Modal, Message} from 'semantic-ui-react'
import useTexts from './useTexts'

import Editor,{useMonaco} from "@monaco-editor/react";

const ConfigEditor =(props)=> {

    const {children, modalParams,resetModal, modalSubmit}=props
    const {getUrl,putUrl, filename,prefix, mimeType='plain/text', language, defaultCode, validator=null}=modalParams[0]
    const {apiGetFile,apiPutFile}= useApi()
    const [code,setCode]=useState(defaultCode)
    const [type,setType]= useState(mimeType)
    const texts=useTexts('FileEditor')
    const editor=useRef(null)
    const [errorMessages,setErrorMessages]=useState(null)

    useEffect(()=>
    {
        const getFile=async ()=>
        {
            const result=await apiGetFile(getUrl+'?t='+Date.now())
            if(result && result.size)
            {
                const myCode=await result.text()
                setCode(myCode)
                setType(result.type)
            }
        }
        getFile()
      
    },[getUrl])



    modalSubmit.current=async ()=> {
        const myCode=editor.current.getValue()
        let validationError={success: true, error: null}
        if(validator)
        {
            validationError=validator(myCode)
        }

        const file = new Blob([myCode], {type});
      
        if(validationError.success)
        {
            const result=await apiPutFile(putUrl,file,filename,prefix ? prefix :'') 
            if(result.success)
                resetModal()
            else
                alert(result.message)
        }
        else
        {
            alert(validationError.error)
        }
    }
    

    return (<>
        
        <Modal.Header as='h3'>{texts.FileEditor} {filename}</Modal.Header>
        <Modal.Content>

            {
                errorMessages &&
                <Message error content={errorMessages}/>
            }
            <Editor
                height="70vh"
                defaultLanguage={language}
                defaultValue={defaultCode}
                value={code}
                theme="vs-dark"
                onMount={(editorRef, monaco)=>{editor.current=editorRef}}
            />
        </Modal.Content>
        {children}
      </>)
    }
    export default ConfigEditor