import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Container,Header,Form,Segment,Modal,Button, Table, Label} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useConfig} from '../lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {CKEditorUpload as TextEditor}  from '../lib20/CKeditor/CKInlineEditor'
import { api,baseUrl } from '../config';
import ActionUI from './ActionUI'

const Translate=(text,translation,onChangeTranslation)=>
{
  
        return(
        <Table.Row>
            <Table.Cell>
                {text}
            </Table.Cell> 
            <Table.Cell>
                <Form.Input 
                    value={translation}
                    onChange={onChangeTranslation}
                    name={text}
             />
            </Table.Cell> 
        </Table.Row> )
   
}





const EditAction=(props)=> {

    const texts=useTexts('/actions')
    const {children, resetModal, modalSubmit, modalParams}=props
    const {createNew,aIndex,actions, setActions, functions,achievements}=modalParams[0]
    const [action,setAction]=useState({triggers:[]})

    const config=useConfig()
    const {apiInsert}=useApi()

    const triggerObjects=['group','person','list','dlist','email']
        .map(o=>({key:o,text:texts.triggerObjects[o],value:o}))
        
    const triggerTypes=['group','person','job','guest','entry','file','extern','action']
        .map(o=>({key:o,text:texts.triggerTypes[o],value:o}))

    const actionTypes=['add','remove','enter','exit']
        .map(o=>({key:o,text:texts.actionTypes[o],value:o}))


    modalSubmit.current=()=> {
        saveAction(action, aIndex)
        resetModal()
    }

    const saveAction=async (data, Index)=> {

        if(!createNew) {
            const result= await apiInsert('/kpe20/actionTemplate/',{...data,UID:actions[Index].UID})
            setActions(acs=>acs.map((ac,i)=>(i===Index?  result.result: ac)))
        }
        else {
            const result= await apiInsert('/kpe20/actionTemplate/',data)
            setActions(acs=>acs.map((ac,i)=>(i===Index? result.result: ac)))
        }
    }

    useEffect(()=> {
        if(aIndex!==null && actions) {

            setAction(actions[aIndex].Data)
        }

    },[aIndex, actions])


  

    const onChange=(ev,{name,value})=> {
       
        setAction(ac=>({...ac, [name]:value}))

    }

    const onChangeDefaults=(ev,{name,value})=>
    {
        // cahnges the defaults taken for new actions of this template
        setAction(ac=>({...ac,defaults:{...ac.defaults,[name]:value}}))
    }

    const LabelArray=({value,options})=>
    (
        value && value.map(m=><Label content={options.find(op=>op.value===m) ? options.find(op=>op.value===m).text : 'invalid'}/>)
    )

    const LabelValue=({value,options})=>
    (
        value && <Label content={options.find(op=>op.value===value) ? options.find(op=>op.value===value).text : 'invalid'}/>
    )
    const onChangeTranslation=(ev,{name,value,index})=>
    {
        setAction(ac=>({...ac,translate:{...ac.translate,[name]:value}}))
    }

    const onChecked=(ev,{name,checked})=>
    {
        setAction(ac=>({...ac, [name]:checked}))
    }

    return (<>
        <Modal.Header as='h3' content={`${texts.edHeader} ${action.name}`}/>
        <Modal.Content scrolling>
            <Form>
                <Form.Input
                    label={texts.Name}
                    placeholder={texts.Name}
                    name='name'
                    onChange={onChange}
                    value={action.name}
                />
            
                <Form.Input
                    label={texts.SortIndex}
                    placeholder={texts.SortIndex}
                    name='sortIndex'
                    onChange={onChange}
                    type='number'
                    value={action.sortIndex}
                />
            
                <Form.Field
                    label={texts.lbTriggerType}
                    control={LabelArray}
                    value={action.triggers}
                    options={triggerObjects}
                />

               <Form.Field
                    label={texts.lbElementType}
                    control={LabelArray}
                    value={action.entries}
                    options={triggerTypes}
                />
                 <Form.Field
                    label={texts.lbType}
                    value={action.action}
                    options={actionTypes}
                    control={LabelValue}
                />

                <Form.Checkbox
                    toggle
                    name='onlyAdmin'
                    checked={action.onlyAdmin}
                    onChange={onChecked}
                    label={texts.admin}
                />
                               
                <Form.Field
                    label={texts.description}
                    control={TextEditor}
                    uploadUrl={'https://'+api+'.'+baseUrl+'/kpe20/files/'+action.UID}
                    onChange={onChange}
                    value={action.description}
                    name='description'
                />
                <Segment>
                    <Header as='h3'>{texts.headerUI}</Header>
                    {
                        action.UIaction &&
                        <ActionUI
                            UIaction={action.UIaction}
                            action={action}
                            onChange={onChangeDefaults}
                            functions={functions}
                            achievements={achievements}
                            data={action.defaults ?? {}}
                            errors={{}}
                            addUIchecks={()=>{}}
                        />
                    }
                    <Header as='h3'>{texts.headerUITranslate}</Header>
                    {action.translate &&
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    {texts.textName}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {texts.translation}
                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                               
                                {
                                   Object.entries(action.translate).map(([text,translation])=>
                                       Translate(text,translation, onChangeTranslation)
                                        
                                    )
                                }

                            </Table.Body>
                        </Table>
                    }
                </Segment>

                
            </Form>
            
        </Modal.Content>
        {children}
    </>)
}
export default EditAction