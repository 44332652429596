import React, {useState, useEffect}  from 'react'
import {Dropdown,Menu,Icon,  Modal, Header, Image, Tab, DropdownItem} from 'semantic-ui-react'
import {useApi, useUser} from '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../useTexts'
import Help from './Help'


const MenuHelp=(props) => {
    const {setModalParams, setSidebarVisible}=props
    const texts=useTexts('menu')





    // make shure, that changes to shares are passed through

    const onClick=async ()=>{
        setModalParams({ 
            addLevel:{
                component:Help,
                closeIcon:true

            }
        })
        setSidebarVisible(false)
    }

    return(

        <Menu.Item key='AvatarHelp'
            onClick={onClick}
            icon='help'
            content={texts.help}
        />
    )

}  

export default MenuHelp
