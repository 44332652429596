import React, {useState, useEffect}  from 'react'
import {Dropdown, Icon, Menu, Accordion, Button, MenuItem} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js'
import useTexts from '../../lib20/useTexts'
import {baseUrl} from '../../config.jsx'
import {useConfig, setTheme, useFakeUser} from '../KPE20context'
import {useHistory, useLocation} from 'react-router-dom'


import DevelopmentInfos from './DevelopmentInfos.jsx'
import FakeUser from '../FakeUser.jsx'
import SelectOrga from '../SelectOrga'


const AvatarMenu=(props) => {

    const texts=useTexts('lib20Texts','Menu')
    const config=useConfig()
    const {apiLogout, apiSelect}=useApi()
    const history=useHistory()
    const location=useLocation()
    const [theme, setTheme]=useState('default')

    const {isDesktop, isAdmin, setModalParams, windowWidth, setSidebarVisible, setChangelogVisible}=props
    const {fakeUser,setFakeUser}=useFakeUser()

    
    const onClick=(ev,{name})=>
    {
        history.push(name)
        setSidebarVisible(false)
    }

    const linkClick=(ev,{name})=>
    {
        window.open(name)
        setSidebarVisible(false)
    }

    const changeTheme=(ev,{value})=>
    {
        setTheme(value)
        document.getElementById('theme-file').href = window.themes[value]
    }

    return(<>
        
        <Dropdown.Divider/>
        <Menu.Item
            active={location.pathname === '/'}
            onClick={onClick}
            icon='home'
            content={texts.Dashboard}
            name='/'
            key='AvatarHome'
        />
        {config.ExtraLinks ?
            <Menu.Item key='ExtraLinks'>
                <Icon name={isDesktop ? 'caret left' : 'caret right'}/>

                <Dropdown icon='' simple  text={config.ExtraLinks.links ? config.ExtraLinks.title : null}>
                    <Dropdown.Menu>
                        {config.ExtraLinks.links?.map((link, index)=>(
                            <Dropdown.Item
                                name={link.url}
                                target='_blank'
                                icon={link.icon}
                                text={link.text}
                                onClick={linkClick}
                                key={index}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
        : null}
       
        <Dropdown.Divider key='AvatarDiv1'/>
        {isAdmin
            ?  <>
                    <Dropdown.Item
                                name={config.AppUrls.admin}
                                target='_blank'
                                icon='sitemap'
                                text={texts.adminApp}
                                onClick={linkClick}
                                key='adminApp'
                            />
                    <Menu.Item
                    onClick={async (e)=>{
                        setModalParams({ 
                            addLevel:{
                                component:FakeUser
                            }
                        })
                        setSidebarVisible(false)
                    }}
                    icon='user'
                    content='Fake User'
                    key= 'FakeUser'
                /> 
                <Menu.Item
                    onClick={async (e)=>{
                        setModalParams({ 
                            addLevel:{
                                component:DevelopmentInfos,
                                size:'fullscreen'
                            }
                        })
                        setSidebarVisible(false)
                    }}
                    icon='info'
                    content='Development Infos'
                    key= 'DevInfos'
                /> 
            </>
            :  ''
        }
        {
            config.orgaUser.userOrgas.length>1 &&
            <Menu.Item
                onClick={async (e)=>{
                    setModalParams({ 
                        addLevel:{
                            component:SelectOrga
                        }
                    })
                    setSidebarVisible(false)
                }}
                icon='industry'
                content={texts.changeOrga}
                key= 'changeOrga'
            /> 
        }
        {
            fakeUser &&
            <Menu.Item 
                onClick={async (e)=>{
                    setFakeUser(null)
                    history.push('/')
                    setSidebarVisible(false)
                }}
                icon='mask'
                content='Reset Fake User'
                key='fakeUser'
            /> 
        } 
      
        <Menu.Item 
            active={location.pathname === '/settings'}
            onClick={onClick}
            icon='setting'
            content={texts.Settings}
            name='/settings'
        />

        <Menu.Item 
            icon='list ul'
            content={texts.versionHistory}
            onClick={() => {setChangelogVisible(true); setSidebarVisible(false)}}
        />

        <Menu.Item key='AvatarLogout'
            onClick={async (e)=>{apiLogout()}}
            icon='sign-out'
            content='Logout'
        />


    </>)

}
export default AvatarMenu
