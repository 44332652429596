import React, { useEffect, useState, useMemo } from 'react'
import {Container,Rail, Grid, Header, List, Loader, Segment, Icon, Image} from 'semantic-ui-react'
import {useApi, useWindowDimensions} from '@wollo-lib/kpe-context'
import {api,baseUrl} from '../../config.jsx'



const RailContainer=({children,railPath,containerWidth})=>
{
    const {width}=useWindowDimensions()
    const centerWidth= width > containerWidth ?  containerWidth : width
    
    return(
        <>
        {width > (containerWidth+400) ?
            
            <Grid  centered columns={3}>
                <Segment>
                    
                    <MyRail side='left' railPath={railPath} key='left'/>
                
                
                    
                    <MyRail side='right' railPath={railPath} key='right'/>
                    
                    <Container style={{width:centerWidth, textAlign:'left'}} key='center'>
                        {children}
                    </Container>
                </Segment>
            </Grid>

                    :
                    <Container style={{width:centerWidth}}>
                        {children}
                    </Container>
            
        }
        </>
    )
}

const MyRail=({railPath,side,width})=>

{
    const {apiSelect,apiGetFile}=useApi()
    const [rail,setRail]=useState(null)
    const [indexHTML,setHtml]=useState("<p>empty page</p>")
    const [timestamp,setTimestamp]=useState(Date.now())
    const [path,setPath]=useState(null)


    useEffect(()=>{
        const getPath=async()=>
        {
            if(railPath.match(/^UUID\-[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[1-5][0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/))
            {
                const parents=await apiSelect(`/kpe20/object/parents/${railPath}`)
                if(!parents.result.includes(railPath))
                    parents.result=[{UID:railPath},...parents.result]
                if(parents.success && parents.result)
                {
                    let found=false
                    for(const parent of parents.result)
                    {
                        const config= await apiGetFile(`/kpe20/files/rail/${parent.UID}/${side}/config.json?date=${timestamp}`)
                        {
                            if(config && !found)
                            {
                                const text=await config.text()
                                const myConfig=JSON.parse(text)
                                if(myConfig.type!=='inherit' )
                                {
                                    setPath(parent.UID)
                                    found=true
                                }
                                else if(myConfig.type==='off')
                                {
                                    setPath(null)
                                    found=true
                                }
                            }
                        }
                    }
                }

            }
            else
            {
                setPath(railPath)
            }
        }
        if(railPath)
            getPath()
        
    },[railPath,side])


    useEffect(()=>{
        const getRail=async ()=>
        {
            const data= await apiGetFile(`/kpe20/files/rail/${path}/${side}/config.json?date=${timestamp}`)
            if(data)
            {
                const myRailText=await data.text()
                const myRail=JSON.parse(myRailText)
                setRail(myRail)
                if(myRail.type==='html')
                {
                    const hdata= await apiGetFile(`/kpe20/files/rail/${path}/${side}/index.html?replaceRoot=1&date=${timestamp}`)
                    if(hdata)
                    {
                        const myHTML=await hdata.text()
                        setHtml(myHTML)
                    }
                }
            }
           
        }
        if(path)
            getRail()
    },[timestamp,path])



   
        return (

        <Rail position={side}>
            {
                rail && rail.files ?
                <Segment textAlign='left'>
                    {
                        (rail && rail.type==='gallery'  && rail.files.length>0 ) &&
                        <>
                        {
                            rail.files.filter(f=>f.image).map(f=>
                                (<img src={`https://${api}.${baseUrl}/kpe20/files/rail/${path}/${side}/${f.fileName}?date=${timestamp}`} width="250"/>)
                            )
                        }
                        </>
                        

                    }
                    {
                         (rail && rail.type==='html'  ) &&
                         <div dangerouslySetInnerHTML={{__html: indexHTML}}/>
                    }
                </Segment>
                :
                ''
            }
            
        </Rail>
    )
    
}
export default RailContainer