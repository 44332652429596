import React, {useState, useReducer,useEffect, useRef}  from 'react';
import {Header, Form, Segment, Modal, Button, Popup} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useConfig} from '../lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import FilterElement from '../lib20/FilterSources/FilterElement'
import FilterAchievement from '../lib20/FilterAchievements/FilterAchievement'
import useClipboard from '../lib20/useClipboard'

const EditFunction=(props)=> {

    const config=useConfig()
    const {apiInsert, apiDelete}=useApi()
    const texts=useTexts('/functions')

    const {children, resetModal, modalSubmit,modalParams}=props
    const {editUID,createNew,functions,setFunctions,achievements}=modalParams[0]
    
    const [myFunction, setFunction]=useState({authorized:{},access:[],writeAccess:[]})
    const [qualificationsChanged, setQualificationsChanged]=useState(false)


    const stageOptions=config.Stage.map((s,Index)=>({text:s, value:Index,key:Index}))
    const categoryOptions=config.FunctionCategories.map((c,Index)=>({key:Index,value:c,text:c}))

    modalSubmit.current=()=> {
        saveFunction(myFunction,editUID,qualificationsChanged)
        setQualificationsChanged(false)
        resetModal()
    }

    const [clip,setClip,updateClip]=useClipboard({
        alwaysUpdate:false,
        filter:(d)=>(d && ['access','filter','writeFilter'].includes(d.type)),
        copyAlert:texts.clipboardCopyAlert,
        browserSupport: texts.clipboardNotAvaillable
    })

    const saveFunction=async (data,editUID,requalify) => {

        if(editUID) {
            let query=''
            if(qualificationsChanged){
                if(window.confirm(texts.requalifyQuestion))
                    query='?requalify=1'
            }
            const result= await apiInsert('/kpe20/function/'+query,{...data,UID:editUID})
            if(result.success)
                setFunctions(acs=>acs.map((ac)=>(ac.UID===editUID ?  result.result: ac)))
        }
        else{
            const result= await apiInsert('/kpe20/function/',data)
            setFunctions(acs=>acs.map((ac)=>(ac.UID===editUID? result.result: ac)))
        }
    }

    const getAccess=(Data)=>
    {
        const access=Data.access ? {...Data.access} : {}
        delete access[0]
        const writeAccess=Data.writeAccess ? {...Data.writeAccess} : {}
        for (let stageNo=1; stageNo<=Math.max(...Data.hierarchies); ++ stageNo) {

            const stage=stageNo.toString()
            access[stage]=access[stage] ? {...access[stage]} : { }
            access[stage]['person']=access[stage]['person'] ? {...access[stage]['person']}: {none:null}
            access[stage]['guest']=access[stage]['guest'] ? {...access[stage]['guest']}: {none:null}
            access[stage]['group']=access[stage]['group'] ? {...access[stage]['group']}: {none:null}
            access[stage]['job']=access[stage]['job'] ? {...access[stage]['job']}: {none:null}
            access[stage]['extern']=access[stage]['extern'] ? {...access[stage]['extern']}: {none:null}
          //  access[stage]['event']=access[stage]['event'] ? {...access[stage]['event']}: {none:null}
            writeAccess['person']=writeAccess['person'] ? {...writeAccess['person']}: {none:null}
            writeAccess['guest']=writeAccess['guest'] ? {...writeAccess['guest']}: {none:null}
            writeAccess['group']=writeAccess['group'] ? {...writeAccess['group']}: {none:null}
            writeAccess['job']=writeAccess['job'] ? {...writeAccess['job']}: {none:null}
           // writeAccess['event']=writeAccess['event'] ? {...writeAccess['event']}: {none:null}

        }
        return {access,writeAccess}
    }

    useEffect(()=>{
        if( functions) {

            const Data= createNew ? {hierarchies:[]}:functions.find(f=>f.UID===editUID).Data
            const {access,writeAccess}=getAccess(Data)
            Data.access=access
            Data.writeAccess=writeAccess
            Data.qualification=Data.qualification ? Data.qualification : {and:[]}
            setFunction(Data)
        }


    },[editUID, functions, createNew])




    const flip=(ev,{name})=> {

        if(functions[name])
            setFunction(ac=>({...ac,[name]:false}))
        else
            setFunction(ac=>({...ac,[name]:true}))
    }

    const onChange=(ev,{name,value})=> {
       
        setFunction(mf=> {
            
            if(name.match(/^access:\d:\D+/)) {

                const [,myStage,type]=name.split(':')
                const stage=myStage.toString()
                const access=mf.access
                value={...access,[stage]:{...access[stage],[type]:value}}
                name='access'
            }
            else if(name.match(/^writeAccess:\D+/)) {

                const [,type]=name.split(':')
                const writeAccess=mf.writeAccess
                value={...writeAccess,[type]:value}
                name='writeAccess'
            }
            if(name!=='hierarchies')
            {
                return {...mf, [name]:value}
            }
            else
            {
               
                const {access,writeAccess}=getAccess({...mf,hierarchies:value})
                return {...mf,access:access,writeAccess:writeAccess,hierarchies:value}


            }
        })
        if(name==='qualification')
        {
            setQualificationsChanged(true)
        }
    }

    const deleteFunction=async()=>
    {
        if(window.confirm(texts.confirmDelete))
        {
            const result=await apiDelete('/kpe20/function/'+editUID)
            if(result.success)
            {
                resetModal()
            }
            else
            {
                window.alert(result.message)
            }
        }
    }

    const copyAccessFilter=(ev,{index})=>
    {
        setClip({
            type: 'access',
            data: myFunction.access
        })
    }

    
    const pasteAccessFilter=()=>
    {
        updateClip()
        if(clip && clip.type && clip.type==='access')
        {
            const data=clip.data
            setFunction(ac=>({...ac, access:data}))
        }
        else
        {
            window.alert(texts.clipboardPasteError)
        }
    }

    const copyHierarchieFilter=(ev,{index})=>
    {
       setClip({
            type: 'filter',
            data: myFunction.access[index]
        })
    }

    const pasteHierarchieFilter=(ev,{index})=>
    {
        updateClip()
        if(clip && clip.type && clip.type==='filter')
        {
            const data=clip.data
            setFunction(ac=>({...ac, access:{...ac.access, [index]:data}}))
        }
        else
        {
            window.alert(texts.clipboardPasteError)
        }
    }
    
    const copyWriteFilter=()=>
    {
        setClip({
            type: 'filter',
            data: myFunction.writeAccess
        })
    }

    const pasteWriteFilter=()=>
    {
        updateClip()
        if(clip && clip.type && clip.type==='filter')
        {
            const data=clip.data
            setFunction(ac=>({...ac, writeAccess:data}))
        }
        else
        {
            window.alert(texts.clipboardPasteError)
        }
    }


    return (<>

        <Modal.Header as='h3' content={createNew ? texts.fHeaderNew : `${texts.fHeader} ${myFunction.name}`}/>
        <Modal.Content scrolling>
            <Form>
            { !createNew &&
                <Button 
                    icon='trash'
                    content={texts.deleteFunction}
                    onClick={deleteFunction}
                    color='red'
                />    
            }
                <Form.Input
                    label={texts.fName}
                    placeholder={texts.fName}
                    name='name'
                    onChange={onChange}
                    value={myFunction.name}
                />

                <Form.Input
                    label={texts.fNameQualified}
                    placeholder={texts.fNameQualified}
                    name='nameQualified'
                    onChange={onChange}
                    value={myFunction.nameQualified}
                />
                <Form.Input
                    label={texts.fNameProvisional}
                    placeholder={texts.fNameProvisional}
                    name='nameProvisional'
                    onChange={onChange}
                    value={myFunction.nameProvisional}
                />
                
                <Form.Select
                    label={texts.hierarchies}
                    selection
                    multiple
                    options={config.Hierarchie.filter((e,i)=>i!==0).map((h,i)=>({key:i, text: h, value:i+1}))}
                    name='hierarchies'
                    value={myFunction.hierarchies}
                    onChange={onChange}

                />
                <Form.Select
                    label={texts.genderPerson}
                    selection
                    multiple
                    options={Object.entries(config.Gender).map(e=> ({key:e[0], text: e[1], value:e[0]}))}
                    name='genderPerson'
                    value={myFunction.genderPerson}
                    onChange={onChange}

                />
                <Form.Select
                    label={texts.genderGroup}
                    selection
                    multiple
                    options={Object.entries(config.GenderGroups).map(e=> ({key:e[0], text: e[1], value:e[0]}))}
                    name='genderGroup'
                    value={myFunction.genderGroup}
                    onChange={onChange}

                />
                <Form.Select
                    label={texts.stage}
                    selection
                    options={config.Stage.map((s,i)=>({key:i, text: s, value:i}))}
                    name='stage'
                    value={myFunction.stage}
                    onChange={onChange}

                />
                <Form.Field
                    control={FilterAchievement}
                    label={texts.qualification}
                    placeholder={texts.qualification}
                    name='qualification'
                    onChange={onChange}
                    achievements={achievements}
                    value={myFunction.qualification}
                />
                <Form.Select
                    label={texts.vote}
                    selection
                    multiple
                    options={config.Hierarchie.filter((e,i)=>i!==0).map((h,i)=>({key:i, text: h, value:i+1}))}
                    name='vote'
                    value={myFunction.vote}
                    onChange={onChange}

                />
                <Header as="h3">{texts.visibility}</Header>
                <Form.Group onMouseEnter={updateClip}>
                        {
                            ( (clip && clip.type && clip.type==='access') ||  (window.ontouchstart !== undefined)) &&
                            <Popup
                                trigger={
                                <Form.Button 
                                    icon='paste' 
                                    color='green' 
                                    onClick={pasteAccessFilter} 
                                    size='mini'
                                    circular 
                                    basic
                                    content='paste'
                                />}
                                content={texts.clipboardPaste}
                            />
                        }
                        
                        <Popup
                            trigger={
                                    <Button
                                        icon='copy'
                                        color='blue'
                                        size='mini'
                                        onClick={copyAccessFilter}
                                        circular
                                        basic
                                        content='copy'
                                    />}
                            content={texts.clipboardCopy}
                        />
                </Form.Group>
            </Form>
                {

                    Object.keys(myFunction.access).map((hierarchie)=>
                        {
                            
 
                                return(
                                <Segment key={hierarchie}>
                                    <Form key={hierarchie}>
                                        <Header as="h4">{texts.hierarchieLevel} {config.Hierarchie[hierarchie]}</Header>
                                            
                                        <Form.Group onMouseEnter={updateClip}>
                                        {
                                            ( (clip && clip.type && clip.type==='filter') ||  (window.ontouchstart !== undefined)) &&
                                                <Popup
                                                    trigger={
                                                    <Form.Button 
                                                        icon='paste' 
                                                        color='green' 
                                                        onClick={pasteHierarchieFilter} 
                                                        size='mini'
                                                        circular 
                                                        basic
                                                        content='paste'
                                                        index={hierarchie}
                                                    />}
                                                    content={texts.clipboardPaste}
                                                />
                                        }
                                                
                                                <Popup
                                                    trigger={
                                                            <Button
                                                                icon='copy'
                                                                color='blue'
                                                                size='mini'
                                                                onClick={copyHierarchieFilter}
                                                                circular
                                                                basic
                                                                content='copy'
                                                                index={hierarchie}
                                                            />}
                                                    content={texts.clipboardCopy}
                                                />
                                        </Form.Group>
           
                                        <Form.Field
                                            key={`${hierarchie}:group`}
                                            control={FilterElement}
                                            label={texts.visGroup}
                                            name={`access:${hierarchie}:group`}
                                            onChange={onChange}
                                            achievements={achievements}
                                            functions={functions}
                                            type='group'
                                            value={myFunction.access && myFunction.access[hierarchie] ? myFunction.access[hierarchie].group : null}
                                            isVisibility={true}
                                        />
                                        <Form.Field
                                            key={`${hierarchie}:person`}
                                            control={FilterElement}
                                            label={texts.visPerson}
                                            name={`access:${hierarchie}:person`}
                                            onChange={onChange}
                                            achievements={achievements}
                                            functions={functions}
                                            type='person'
                                            value={myFunction.access && myFunction.access[hierarchie] ? myFunction.access[hierarchie].person: null}
                                            isVisibility={true}
                                        />
                                        <Form.Field
                                            key={`${hierarchie}:guest`}
                                            control={FilterElement}
                                            label={texts.visGuest}
                                            name={`access:${hierarchie}:guest`}
                                            onChange={onChange}
                                            achievements={achievements}
                                            functions={functions}
                                            type='person'
                                            value={myFunction.access && myFunction.access[hierarchie] ? myFunction.access[hierarchie].guest: null}
                                            isVisibility={true}
                                        />
                                        <Form.Field
                                            key={`${hierarchie}:extern`}
                                            control={FilterElement}
                                            label={texts.visExtern}
                                            name={`access:${hierarchie}:extern`}
                                            onChange={onChange}
                                            achievements={achievements}
                                            functions={functions}
                                            type='extern'
                                            value={myFunction.access && myFunction.access[hierarchie] ? myFunction.access[hierarchie].extern: null}
                                            isVisibility={true}
                                        />
                                        <Form.Field
                                            key={`${hierarchie}:job`}
                                            control={FilterElement}
                                            label={texts.visJob}
                                            name={`access:${hierarchie}:job`}
                                            onChange={onChange}
                                            achievements={achievements}
                                            functions={functions}
                                            type='job'
                                            value={myFunction.access && myFunction.access[hierarchie] ? myFunction.access[hierarchie].job: null}
                                            isVisibility={true}
                                        />
                                        {/* <Form.Field
                                            key={`${hierarchie}:event`}
                                            control={FilterElement}
                                            label={texts.visEvent}
                                            name={`access:${hierarchie}:event`}
                                            onChange={onChange}
                                            achievements={achievements}
                                            functions={functions}
                                            type='event'
                                            value={myFunction.access && myFunction.access[hierarchie] ? myFunction.access[hierarchie].event: null}
                                            isVisibility={true}
                                        />*/}
                                    
                                    </Form>
                                </Segment>
                            )

                        }
                    )
                            
                }
                <Header as="h3">{texts.writeAccess}</Header>
                <Form>
                    <Form.Group onMouseEnter={updateClip}>
                    {
                        ( (clip && clip.type && clip.type==='filter') ||  (window.ontouchstart !== undefined)) &&
                        <Popup
                            trigger={
                            <Form.Button 
                                icon='paste' 
                                color='green' 
                                onClick={pasteWriteFilter} 
                                size='mini'
                                circular 
                                basic
                                content='paste'
                            />}
                            content={texts.clipboardPaste}
                        />
                    }
                    
                <Popup
                    trigger={
                            <Button
                                icon='copy'
                                color='blue'
                                size='mini'
                                onClick={copyWriteFilter}
                                circular
                                basic
                                content='copy'
                            />}
                    content={texts.clipboardCopy}
                />
                </Form.Group>                       
                </Form>
                <Segment >
                    <Form >
                     
                        <Form.Field

                            control={FilterElement}
                            label={texts.visGroup}
                            name={`writeAccess:group`}
                            onChange={onChange}
                            achievements={achievements}
                            functions={functions}
                            type='group'
                            value={myFunction.writeAccess ? myFunction.writeAccess.group : null}
                            isVisibility={true}
                        />

                        <Form.Field
                            control={FilterElement}
                            label={texts.visPerson}
                            name={`writeAccess:person`}
                            onChange={onChange}
                            achievements={achievements}
                            functions={functions}
                            type='person'
                            value={myFunction.writeAccess  ? myFunction.writeAccess.person: null}
                            isVisibility={true}
                        />

                        <Form.Field
                            control={FilterElement}
                            label={texts.visGuest}
                            name={`writeAccess:guest`}
                            onChange={onChange}
                            achievements={achievements}
                            functions={functions}
                            type='person'
                            value={myFunction.writeAccess  ? myFunction.writeAccess.guest: null}
                            isVisibility={true}
                        />

                        <Form.Field
                            control={FilterElement}
                            label={texts.visExtern}
                            name={`writeAccess:extern`}
                            onChange={onChange}
                            achievements={achievements}
                            functions={functions}
                            type='extern'
                            value={myFunction.writeAccess  ? myFunction.writeAccess.extern: null}
                            isVisibility={true}
                        />

                        <Form.Field
                            control={FilterElement}
                            label={texts.visJob}
                            name={`writeAccess:job`}
                            onChange={onChange}
                            achievements={achievements}
                            functions={functions}
                            type='job'
                            value={myFunction.writeAccess && myFunction.writeAccess ? myFunction.writeAccess.job: null}
                            isVisibility={true}
                        />
                       {/*  <Form.Field
                            control={FilterElement}
                            label={texts.visEvent}
                            name={`writeAccess:event`}
                            onChange={onChange}
                            achievements={achievements}
                            functions={functions}
                            type='event'
                            value={myFunction.writeAccess && myFunction.writeAccess ? myFunction.writeAccess.event: null}
                            isVisibility={true}
                        />*/}
                    
                    </Form>
                </Segment>
                <Form>
                    <Form.Select
                        label={texts.categories}
                        selection
                        name='categories'
                        multiple
                        options={categoryOptions}
                        value={myFunction.categories}
                        onChange={onChange}
                    />
                </Form>
            
        </Modal.Content>
        {children}
    </>)
}
export default EditFunction