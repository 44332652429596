import React,{ useState, useEffect, useRef}  from 'react';
import {Segment} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import LogicalElement from './LogicalElement';
import AchievementElement from './AchievementElement';




const FilterAchievement=(props)=>
{
    const texts=useTexts('lib20Texts','FilterElement')


   
    
    const logTags=[
        { value:'and', key:'and' ,text:texts.lAnd, color:'orange'}, 
        { value:'or', key:'or', text:texts.lOr, color:'green'}, 
        { value:'xor' , key:'xor',text:texts.lXor, color:'red'},
        { value: 'nand', key:'nand',text:texts.lNand, color:'violett'},
        { value: 'nor', key:'nor', text:texts.lNor, color:'grey'}
    ]

    const {type,value,onChange,removeEntry,achievements,name}=props
    const [tag,setTag]= useState(null) // tags are the combination of tag and oper
    const [para,setPara]=useState(null)


    let logical= ['and','or','nand','nor','xor'].includes(tag)


    const removeEntryFunction=(index)=>
    {
        const newArray=para.filter((el,i)=>(i!==index))
      /*  if(newArray.length>1)*/
            setPara(newArray)
      /*  else
        {
            const tag=Object.keys(newArray[0])[0]
            const para= newArray[0][tag]
            setTag(tag)
            setPara(para)
        }*/
    }



    useEffect(()=>
    {
        // getter use effect
        if(value && Object.keys(value).length>0)
        {
             const tag= Object.keys(value)[0]
             logical= ['and','or','nand','nor','xor'].includes(tag)
             if(logical)
            {
                setTag(tag)
                setPara(value[tag])
            }
            else
            {
               setTag(value)
               setPara(null)
            }

        }
        else
        {
            setTag(null)
            setPara(null)   
        }
      
    },[value])

    useEffect(()=>{
        if(tag  && onChange)
        {
            if(logical && para)
            {
                if(JSON.stringify(value)!==(JSON.stringify({[tag]:para})))
                    onChange({},{value:{[tag]:para},name:name})
                
            }
            else
            {
                if(value!==tag)
                    onChange({},{value:tag,name:name})
            }
        }
    }, [tag,para])



    const handleChange=(ev,{name,value})=>
    {
        if(name==='tag')
        {
            setTag(value)
            if(['and','or','nand','nor','xor'].includes(value))
            {
                setPara([])
            }
            else
            {
                setPara(null)
            }
        }
        if(name==='para')
            setPara(value)
    }

    const setSubFilter=(ev,{value,name})=>
    {
        if(name ==-1)
        {
            // add it
            setPara(state=>([...state,value]))
        }
        else
        {
            setPara(state=>state.map((el,Index)=>
            (
                Index===name ? value : el
            )
            ))
        }

       
    }

    

    const addLevel=(ev,{value})=>
    {
        setTag(value)
        if(logical)
        {
            setPara([{[tag]:para}])    
        }
        else
        {
            setPara([{[`${tag}`]:para},{}])
        }
    }

    return (
        <Segment compact size='small'>
       
        {
         logical  ? 
             <LogicalElement
                 tag={tag}
                 setTag={setTag}
                 para={para}
                 setPara={setPara}
                 type={type}
                 setSubFilter={setSubFilter}
                 removeEntryFunction={removeEntryFunction}
                 logTags={logTags}
                 name={name}
                 addLevel={addLevel}
                 achievements={achievements}
             />
         :
             <AchievementElement
                 tag={tag}
                 setTag={setTag}
                 para={para}
                 setPara={setPara}
                 removeEntry={removeEntry}
                 value={value}
                 name={name}
                 type={type}
                 logTags={logTags}
                 addLevel={addLevel}
                 achievements={achievements}
             />
        }       
    </Segment>
    ) 
}

export default FilterAchievement
