import React, {useState, useRef, useEffect} from 'react'
import {Modal, Container, Button, Step, Portal} from 'semantic-ui-react'
import useTexts from './useTexts'


const CentralModal=(props) => {
    
    const {modalParams,setModalParams}=props

    const [saving, setSaving] = useState(false)
    const [blockSave, setBlockSave] = useState(false)
    const texts=useTexts('/CentralModal')
    const modalSubmit=useRef(async () => {})            // central placeholder, which is overwritten, when a modal is open
    const modalSubmitAndNew = useRef(async () => {})


    const submit=async () => {
        setSaving(true)
        await modalSubmit.current()
        setSaving(false)
    }

    const submitAndNew = async () => {
        setSaving(true)
        await modalSubmitAndNew.current()
        setSaving(false)
    }

    

    const resetModal=() => {
        setModalParams({close:null})
    }

    const myModalParams= modalParams.length>0 ? modalParams[0] : {}
    const MyModal= modalParams.length>0 ? myModalParams.component  : null
    const modalSize= myModalParams.size ? myModalParams.size : 'tiny'
    const closeOnEscape = myModalParams.closeOnEscape ? myModalParams.closeOnEscape : true
    const closeOnDimmerClick = myModalParams.closeOnDimmerClick ? myModalParams.closeOnDimmerClick :true
    const saveAndNew = myModalParams.saveAndNew ? myModalParams.saveAndNew : false

    const PostButtonComponent= modalParams.length>0 && modalParams[0].postButtonComponent ? modalParams[0].postButtonComponent : null

    return (
        <>
        { MyModal===null ? '' :
            <Modal as={Container}
                centered={false}
                open={modalParams.length>0 && modalParams[0].component}
                size={modalSize}
                dimmer={myModalParams.dimmer}
                closeIcon={myModalParams.closeIcon}
                onClose={resetModal}
                closeOnEscape={closeOnEscape}
                closeOnDimmerClick={closeOnDimmerClick}
            >
                
                <MyModal 
                    {...props}
                    resetModal={resetModal}
                    modalSubmit={modalSubmit}
                    modalSubmitAndNew={modalSubmitAndNew}
                    setModalParams={setModalParams}
                    setBlockSave={setBlockSave}
                >
                    <Modal.Actions>
                       
                        <Button.Group fluid>
                            <Button
                                icon='save'
                                content={texts.save}
                                onClick={submit}
                                positive
                                loading={saving}
                                disabled={blockSave}
                            />
                            {saveAndNew && <Button 
                                icon='add'
                                content={texts.saveAndNew}
                                onClick={submitAndNew}
                                primary
                                loading={saving}
                                disabled={blockSave}
                            />}
                            <Button
                                icon='cancel'
                                content={texts.cancel}
                                onClick={resetModal}
                                negative
                            />
                        </Button.Group>

                    </Modal.Actions>
                </MyModal>
            </Modal>
        }
    </> )
}
export default CentralModal