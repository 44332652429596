import React,{useEffect, useState} from 'react'
// this hook loads the changelogs from the changelog file
import {useApi} from '@wollo-lib/kpe-context'
import { app } from '../../config'
import {useConfig, useSetConfig} from '../KPE20context'
import YAML from 'yaml'
import { compareVersions } from 'compare-versions';

const useChangelog=()=>
{
    const [changeLog,setChangelog]=useState([])
    const {apiGetFile, apiPutFile}=useApi()
    const config=useConfig()
    useEffect(()=>{
        const getChangelog=async ()=>
        {
            const  myChangeLogFile= await apiGetFile(`/kpe20/files/${app}/config/changelog.yaml?data=${Date.now()}`)
            {
               const myChangeLog=await myChangeLogFile.text() 
               const changes=YAML.parse(myChangeLog)
               setChangelog(changes.filter(change=> compareVersions(change.version,config.requiredFrontendVersion)<=0))
            }
            

        }
        getChangelog()
    },[config.requiredFrontendVersion])
    return changeLog
}
export default useChangelog