import React,{useEffect} from 'react'
import useTexts from './useTexts'
import { Header, Container, Segment, Button } from 'semantic-ui-react'

export const  ConnectionError=(props)=>
{


    useEffect(() => {
        // Check if this is the first load by seeing if our object exists in local storage
        const alreadyShown= sessionStorage.getItem('connectionErrorShown')
        if (alreadyShown) {
            sessionStorage.removeItem('connectionErrorShown')
            window.history.back()
        }
        else
        {
             // If it's the first load, set the flag in local storage to true and reload the page
             sessionStorage.setItem('connectionErrorShown', 1);
        }
      }, []);


    const texts=useTexts('lib20Texts')
    return(
        <>  
            <Container text>
            <Header as="h1" content={texts.connectionErrorHeader}/>
            
                <Segment
                    dangerouslySetInnerHTML={{__html: texts.connectionErrorText}}
                />
                <a href='/'><Button color='blue'>{texts.again}</Button></a>
            </Container>
    
            
        </>
    )
}
export default ConnectionError