import React, {useState, useEffect}  from 'react'
import {Icon,  Modal, Tab, List, Segment, Button} from 'semantic-ui-react'
import {useApi, useUser} from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {useConfig} from '../KPE20context'
import { app } from '../../config'


const DevelopmentInfos=(props) => {

    const user=useUser()
    const config=useConfig()
    const {apiSelect,apiGetFile,apiPutFile}=useApi()

    const [panes, setPanes] = useState([])
      
    const DevelopmentInfos=(props) => {
    
        const user=useUser()
        const config=useConfig()
        const {apiSelect}=useApi()
        const [memberData, setMemberData]=useState()
        const [groupData, setGroupData]=useState()
        const [online,setOnline]=useState([])
        const {resetModal}=props
        const mbrUID=config.orgaUser.UID
        const grpUID=config.orgaUser.Data.UIDgroup
        
        useEffect(()=>{
            const getMemberData = async () => {
                const myMemberData= await apiSelect(`/kpe20/person/${mbrUID}/?timestamp=${Date.now()}&parent=true`)  
                if (myMemberData.success) {
                    setMemberData(myMemberData.result)
                }
            }
            getMemberData()
    
    
            const getGroupData = async () => {
                const myGroupData=await apiSelect(`/kpe20/group/${grpUID}/?timestamp=${Date.now()}&parent=true&siblings=true`)
                if (myGroupData.success) {
                    setGroupData(myGroupData.result)
                }
            }
            getGroupData()
    
            const getOnline= async()=> {
                const result= await apiSelect('/kpe20/analisys/online')
                if(result.success)
                    setOnline(result.result)
            }
            getOnline()
    
    
        }, [mbrUID, grpUID])
     
        
    
        return(
            <>
                <Icon name='close' onClick={async (e)=>{resetModal()}} style={{ position: 'absolute', right:'0px'}} />
                <Modal.Header>Development Infos</Modal.Header>
    
                <Tab style={{overflow: 'auto', maxHeight:window.innerHeight - 100}} 
                    panes={[                
                        {menuItem: 'Loaded Config', render: () => 
                            <Tab.Pane>
                                <Tab panes={[
                                    {menuItem: 'Allgemein', render: () => <Tab.Pane as='pre'>{JSON.stringify(config,null,4)}</Tab.Pane>},
                                    {menuItem: 'orgaUser', render: () => <Tab.Pane as='pre'>{JSON.stringify(config.orgaUser,null,4)}</Tab.Pane>},
                                ]} />
                            </Tab.Pane>},
                        {menuItem: 'Logged In as', render: () => <Tab.Pane as='pre'>{JSON.stringify(user,null,4)}</Tab.Pane>},
                        {menuItem: 'Member Data', render: () => <Tab.Pane as='pre'>{JSON.stringify(memberData,null,4)}</Tab.Pane>},
                        {menuItem: 'Group Data', render: () => <Tab.Pane as='pre'>{JSON.stringify(groupData,null,4)}</Tab.Pane>},
                        
                        {menuItem: 'Currently Online', render: () => 
                            <Tab.Pane as={Segment}>
                                <List divided relaxed items=
                                    {online.map((display, index)=>(<List.Content key={index} content={display.Display} />))} 
                                />
                                {/* <pre>{JSON.stringify(online,null,4)}</pre> */}
                            </Tab.Pane>},
                    ]} 
                />
            </>
        )
    
    }

    const {resetModal}=props
    const mbrUID=config.orgaUser.UID
    const grpUID=config.orgaUser.Data.UIDgroup

    const pushToPanes = (title, data) => {
        setPanes(p => [
            ...p, 
            {menuItem: title, render: () => <Tab.Pane as='pre'>{JSON.stringify(data,null,4)}</Tab.Pane>}
        ])
    }

    
    useEffect(() => {
        const fetchData = async () => {
            setPanes([
                {menuItem: 'Loaded Config', render: () => <Tab.Pane>
                    <Tab panes={[
                        {menuItem: 'Allgemein', render: () => <Tab.Pane as='pre'>{JSON.stringify(config,null,4)}</Tab.Pane>},
                        {menuItem: 'orgaUser', render: () => <Tab.Pane as='pre'>{JSON.stringify(config.orgaUser,null,4)}</Tab.Pane>},
                    ]} />
                </Tab.Pane>}
            ])
            pushToPanes('Logged In as', user)

            switch (app) {
                case 'db':
                    const myMemberData = await apiSelect(`/kpe20/person/${mbrUID}/?timestamp=${Date.now()}&parent=true`)  
                    if (myMemberData.success) {
                        pushToPanes('Person Data', myMemberData.result)
                    }
                    const myGroupData = await apiSelect(`/kpe20/group/${grpUID}/?timestamp=${Date.now()}&parent=true&siblings=true`)
                    if (myGroupData.success) {
                        pushToPanes('Group Data', myGroupData.result)
                    }
                    break

                case 'events':
                    const myEvents = await apiSelect('/event/event')
                    if (myEvents.success) {
                        pushToPanes('Events', myEvents.result)
                    }
                    break

                case 'locations':
                    const myLocations= await apiSelect(`/location/location/`)  // with filtering
                    //const result= await apiSelect('/db/Locations')        // without filtering
                    if(myLocations.success) {
                        pushToPanes('Locations', myLocations.result)
                    }
                    break
            }


            const result = await apiSelect('/kpe20/analisys/online')
            if (result.success) {
                setPanes(p => [
                    ...p,
                    {menuItem: 'Currently Online', render: () => <Tab.Pane as={Segment}>
                        <List divided relaxed items=
                            {result.result.map((display, index)=>(<List.Content key={index} content={display.Display} />))} 
                        />
                    </Tab.Pane>}
                ])
            }
        }
        fetchData()

    }, [mbrUID, grpUID])
 
    

    return(
        <>
            <Icon name='close' onClick={async (e)=>{resetModal()}} style={{ position: 'absolute', right:'0px'}} />
            <Modal.Header>Development Infos</Modal.Header>
            <Tab style={{overflow: 'auto', maxHeight:window.innerHeight - 100}} 
                panes={panes.map(pane => pane)} 
            />
            {/* {JSON.stringify(panes, null, 4)} */}
        </>
    )

}
export default DevelopmentInfos