import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Container,Header,Form,Segment,Modal,Button} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {useRoot} from '../lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'


const Admins =(props)=>
{
    const [adminsList,setAdminsList]=useState(null)
    const [actAdmins,setActAdmin]=useState(null)
    const texts=useTexts('/admins')
    const [root]=useRoot()
    const {apiSelect,apiInsert}=useApi()

    
    useEffect(()=>{
        const getAdmins=async ()=>
        {
            const res=await apiSelect('/kpe20/admins')
            if(res.success)
            {
                setAdminsList(res.result)
                setActAdmin(res.result)
            }
        }
        if(root)
            getAdmins()

    },[root])

    const handleChange=(ev,{value,admin})=>
    {
        setAdminsList(value)
    }

    const handleSave=async(ev,{})=>
    {
        if(window.confirm(texts.confirmSave))
        {
            await apiInsert('/kpe20/admins/'+adminsList,{empty:''})
        }
    }



    return(
        <Container>
            <Form>
            <Header>{texts.adminsTitle}</Header>
            <Segment>
                {texts.adminsExplanation}
            </Segment>
        {/*    <Form.Field 
                control={SearchSelect}
                lable={texts.adminsLabel}
                placeholder={texts.adminsPlaceholder}
                value={adminsList}
                onChange={handleChange}
                api = "kpe20"
                SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['list','dlist'])}
                Value2TitleUrl={'/kpe20/Value2Title?types='+JSON.stringify(['list','dlist'])}
                autoSelect
               

        />*/}
            <Form.Field 
                control={SearchSelect}
                lable={texts.adminsLabel}
                placeholder={texts.adminsPlaceholder}
                value={adminsList}
                onChange={handleChange}
                api = "kpe20"
                SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['list','dlist'])}
                Value2TitleUrl={'/kpe20/Value2Title?types='+JSON.stringify(['list','dlist'])}
                autoSelect
                name='users'

            />
            <Form.Button
                icon='save'
                color='green'
                disabled={!adminsList || adminsList=== actAdmins}
                onClick={handleSave}
            />
           
        </Form>
    </Container>
    )
}
 export default Admins