import React, { useRef, useEffect, useState, useContext } from 'react'
import { baseUrl, api , app} from '../config'
import { useApi, useUser } from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js'//'@wollo-lib/kpe-context'
import { useFakeUser } from './KPE20context'
import { v4 } from 'uuid'
import useTexts from './useTexts'



const timeout = 10000  // send empty message after this time to make sure we are still connected



const useWebsocket = (setWsStatus, setUpdateConfig) => {
    const [timestamp, setTimestamp] = useState(Date.now())
    const [updateTemplate, setUpdateTemplate] = useState({ update: 'all' })
  
    const wsCallback = useRef({})
    const root = useRef()
    const fakeUser = useRef()
    const { apiSelect } = useApi()
    const lastTimestamp=useRef(Date.now())
    const updateTimeLimit=useRef(1000)

    const status =useRef('closed')
    const pingTimeout = useRef()
    const wsID = useRef(v4())
    const setQueueStatus= useRef(0)
    const texts=useTexts('lib20Texts')
        const ws = useRef({})
    const wsBuffer = useRef([])
    const messageHandler = (data) => //web socket message handler
    {
        if (data && data.update) {
            const timeDiff= Date.now()-lastTimestamp.current
            if(timeDiff > updateTimeLimit.current)
            {
                setTimestamp(data.timestamp)
                lastTimestamp.current=Date.now()
                console.log(Date.now())
            }
            else
            {
                setTimeout(()=>{
                        setTimestamp(data.timestamp)
                        lastTimestamp.current=Date.now()
                        console.log(Date.now())
                    }
                    ,updateTimeLimit.current- timeDiff
                )
            }
        }
        if (data && data.object && wsCallback.current !== null && wsCallback.current.cb) {

            wsCallback.current.cb(data.value)
        }
        if (data && data.updateTemplate) {
            if (data.root === root.current)
                setUpdateTemplate(data.updateTemplate)
        }
        if(data && data.queueStatus!==undefined)
        {
            setQueueStatus.current(data.queueStatus)
        }
        if (data && data.user && data.baseUser) {
            if(data.user !== data.baseUser)
            {
                wsCallback.current.setFakeUser(data.fakeLogin)
                fakeUser.current=data.fakeLogin
            }
            else
            {
                wsCallback.current.setFakeUser(null)
                fakeUser.current=null
            }
        }
        if(data && data.updateConfig && data.app && (data.app==='admin' || data.app===app))
        {
            setUpdateConfig(data.updateConfig)
        }
    }

    const refreshCallback = () => {
        if (wsCallback.current.UIDmonitor)
            sendMessage(JSON.stringify({ type: 'monitor', UID: wsCallback.current.UIDmonitor }))
        if (wsCallback.current.UIDchanged)
            sendMessage(JSON.stringify({ type: 'monitorObject', UID: wsCallback.current.UIDchanged }))

    }

    const setCallback = (setData) => {
        if (setData) {
            const { UIDmonitor, UIDchanged, root: wsRoot, cb , setQueueStatus:mySetQueueStatus, setFakeUser, updateTimeLimit : uTimeLimit} = setData

            if (UIDmonitor && (!wsCallback.current || wsCallback.current.UIDmonitor !== UIDmonitor)) {
                sendMessage(JSON.stringify({ type: 'monitor', UID: UIDmonitor }))
                wsCallback.current.UIDmonitor = UIDmonitor
            }
            if (UIDchanged && (!wsCallback.current || wsCallback.current.UIDchanged !== UIDchanged)) {
                sendMessage(JSON.stringify({ type: 'monitorObject', UID: UIDchanged }))
                wsCallback.current.UIDchanged = UIDchanged
            }
            if (wsRoot) {
                if(root.current!==wsRoot)
                    sendMessage(JSON.stringify({type:'monitorRoot',UIDroot:wsRoot}))
                root.current = wsRoot
               
            }
            if (cb) {
                wsCallback.current.cb = cb
            }
            if(setFakeUser)
            {
                wsCallback.current.setFakeUser=setFakeUser
            }
            setQueueStatus.current=mySetQueueStatus
            
            updateTimeLimit.current=uTimeLimit ? uTimeLimit : updateTimeLimit.current

        }
        else {
            if (wsCallback.current) {
                sendMessage(JSON.stringify({ type: 'monitor', UID: null }))
                sendMessage(JSON.stringify({ type: 'monitorObject', UID: null }))
                wsCallback.current = { root: wsCallback.current.root }
            }
        }


    }


    const heartbeat = () => {
        let nextTimeout = status.current==='reset' ? 10000 : 4000
        if(status.current==='pong')
        {
            clearTimeout(pingTimeout.current);
             // send next ping
            setWsStatus('ping')
        }
       
        ws.current.send(JSON.stringify({ type: 'ping', root: root.current }))
        // Use `WebSocket#terminate()`, which immediately destroys the connection,
        // instead of `WebSocket#close()`, which waits for the close timer.
        // Delay should be equal to the interval at which your server
        // sends out pings plus a conservative assumption of the latency.
        pingTimeout.current = setTimeout(() => {

            // this timeout will be cleared on received pong
            // under working network condtitions it is not called
           
            if (status.current === 'reset') {
                // closing/ re-opening did not work
                status.current='resetAlerted'
               if(window.confirm(texts.webSocketTimeout))
               {
                    window.location.href='/' 
               }
               else
               {
                    status.current='reset'
               }

            }
            if (ws.current) {
                // first time try to close and re-open
                status.current = 'reset'
                setWsStatus('reset')
                ws.current.close()
                ws.current.closing = true
                nextTimeout = 10000 // give longer timeout before next message
            }
          
        }, nextTimeout);
    }

    const sendBuffer = () => {
        if (wsBuffer.current.length && ws.current.readyState === 1) {
            for (const message of wsBuffer.current) {
                ws.current.send(message)
            }
            wsBuffer.current = []
        }
       
    }

    const sendMessage = (newMessage) => {
        if (ws.current.readyState === 1) {
            sendBuffer()
            ws.current.send(newMessage)
        }
        else {
            wsBuffer.current.push(newMessage)
        }
    }

    const createWebSocket = async () => {
        if (status.current === 'closed' || status.current=== 'reset') {
            try {
                ws.current = new WebSocket('wss://kpe20.' + baseUrl.replace('/api', '') + '?id=' + wsID.current);
                const openTimeout= setTimeout(() => {
                    // if after the timeout the web socket is not in state open, then try to reload the whole page
                    if(status.current==='closed')
                    {
                        if(window.confirm(texts.webSocketError))
                            window.location.reload()
                    }
                },10000)

               
            }
            catch (e) {
                ws.current = null
                console.error('web socket connection failed', e)
                status.current = 'closed'
                setWsStatus('closed')

            }

            ws.current.onopen = async () => {
               
                // start ping pong
                heartbeat()
             
                // set the current root orga in case of web socket reconnection, as we may have a new session at the server now
               if (root.current && status.current==='reset') {
                    if(!fakeUser.current)
                    {
                        await apiSelect(`/kpe20/${app}/` + root.current,'kpe20')
                    }
                    else
                    {
                        await apiSelect('/kpe20/'+app+'/'+root.current+'?fakeUser='+fakeUser.current,'kpe20')
                    }
                    // set callbacks for this socket
                    refreshCallback()

                }
                if(status.current !=='reset')
                    status.current='ping'
                setWsStatus('open')
            };

            ws.current.onmessage = (message) => {
                if (message.data === 'pong') {
                    status.current='pong'
                    heartbeat()
                    return
                }
                else {
                    try {
                        const msgObject = JSON.parse(message.data)
                        messageHandler(msgObject)
                    }
                    catch (e) { }
                }

            }

            ws.current.onclose = () => {
                clearTimeout(pingTimeout.current);
                if (status.current === 'reset') {
                    // keep the reset status
                    setWsStatus('reset')
                    createWebSocket()
                }
                else {
                    status.current = 'closed'
                    setWsStatus('closed')
                }

            };
        }
    }

    useEffect(() => {
        createWebSocket()
        // start loop which checks connection every minute
        ws.current.interval = setInterval(async () => {
            if (ws.current && ws.current.readyState === 3) {
                // reconnect server
                //cancelKeepAlive()
                clearTimeout(pingTimeout.current);
                ws.current.close()
                ws.current = null
                createWebSocket()
            }
            else if (ws.current && ws.current.readyState === 1) {
                sendBuffer()
            }

        }, 1000);

        // close web socket connection, when leaving
        return (() => {
            if (ws.current) {
                //cancelKeepAlive()

                ws.current.close()
            }
        })
    }, [])
    return { ws: ws.current, setTimestamp: setTimestamp, timestamp: timestamp, wsCallback: wsCallback, setCallback: setCallback, updateTemplate: updateTemplate}

}

export default useWebsocket
