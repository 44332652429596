import React, {createContext, useContext, useState, startTransition, useEffect, useRef} from 'react'
import {Menu, Container, Segment, Loader} from 'semantic-ui-react'
import useWebsocket from './useWebsocket'
import {useApi} from '@wollo-lib/kpe-context' //'./kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from './useTexts'
import { compareVersions } from 'compare-versions';
import { app ,version} from '../config.jsx'
import { contextTexts } from './lib20Texts'

export const KPE20Context= createContext({})

export const WsContext= createContext({})

const KPE20ContextProvider = ({children})=>
{
    const {apiSelect,apiGetFile}=useApi()

    const [handleMessage, setHandleMessage]=useState((message)=>{})
    const [root,setRoot]=useState('UUID-37827965-6ef1-11ec-ac6f-0242ac190008')
    const [fakeUser,setFakeUser]=useState(null)
    const [config,setConfig]=useState(null)
    const [updateConfig,setUpdateConfig]=useState(Date.now())

    // const texts=useTexts('App')

    // web socket status
    const [wsStatus,setWsStatus]=useState('closed')
    const [queueStatus,setQueueStatus]=useState(null)
    const texts=useTexts()

    const [langTexts,setLangTexts]=useState({})
    
    const setMyWsStatus=((status)=>
        startTransition(()=>{
            setWsStatus(status)
        })
    
    )

    const ws=useWebsocket(setMyWsStatus,setUpdateConfig)

    const [userLang, setUserLang] = useState(null)


    useEffect(()=>
    {
        const getDefaultTexts=async()=>
        {
            if(!window[`${app}Texts`])
            await new Promise((fullfill,reject)=>
            {
                const script = document.createElement("script");
                script.async = true;
                if(window.runtimeConfig.devMode)
                    script.src = `/languageMaster.js?version=${Date.now()}`;
                else
                    script.src = `/languageMaster.js?version=${version}`;
                document.head.appendChild(script);
                script.onload = () => {
                
                    fullfill()
                }
            })
        }
        const getTexts= async () =>
        {
            await getDefaultTexts()
            if(!userLang )
            {
              

                setLangTexts({contextTexts,...window[`${app}Texts`]})
              
            }
            else if(userLang)
            {   
                const result=await apiGetFile(`/kpe20/languages/${app}/${userLang}.json`,'kpe20')
                if(result && result.size)
                {
                    const texts=await result.text()
                    setLangTexts(JSON.parse(texts))
                }
                    
            }
        }
        if(config)
            getTexts()
    },[userLang, config])

    useEffect(()=>{

        if(config?.orgaUser)
        {
            if(!config || !config.orgaUser.Data.settings)
                setUserLang(config.defaultLanguage)
            else 
                setUserLang(config?.orgaUser.Data.settings.language)
        }

    }, [config])

  
    useEffect(() => {
        const initRoot=async ()=> {
            let result
            if(!fakeUser)
            {
                result=await apiSelect('/kpe20/'+app+'/'+root,'kpe20')
            }
            else
            {
                result=await apiSelect('/kpe20/'+app+'/'+root+'?fakeUser='+fakeUser,'kpe20')
            }
            if(result.success && result.result) 
            {
                if(result.result.requiredFrontendVersion && compareVersions(version,result.result.requiredFrontendVersion)<0)
                {
                    // if there is a stored missed version and if it is only a minor version upgrade, we will skip reloading for one hour
                    const missedVersion=sessionStorage.getItem('missedVersion')
                    const lastUpdateAttempt=sessionStorage.getItem('lastUpdateAttempt')
                    const [currentMajor,currentMinor,currentSub]=version.split('.')
                    const [requiredMajor,requiredMinor, requiredSub]=result.result.requiredFrontendVersion.split('.')
                    
                    // so we try to update, if we have not yet tried before one hour ago
                    if( !missedVersion ||  Date.now()-lastUpdateAttempt>3600000   )
                    {
                        // try to perform the update 
                        caches.keys().then(function(names) {
                            for (let name of names)
                                caches.delete(name);
                        });
                        // set the missed version It will be overwritten, if the update was successfull
                        sessionStorage.setItem('missedVersion',version)
                        sessionStorage.setItem('lastUpdateAttempt',Date.now())
                        window.location.reload(true)
                       
                    }
                    else if(currentMajor!==requiredMajor)
                    {
                        // issue a strong warning to wait for the update to appear online
                        window.alert(`at least version ${requiredMajor}.0.0 is required for the current backend, but only ${version} was availlable. You should not use the app, until this issue is fixed`)
                        window.location.reload(true)
                    }
                    // if the major versions match, we are just issue a warning every hour, as the app should still function
                    else if((Date.now()-lastUpdateAttempt>3600000 )>3600000)
                    {      
                        window.alert(`new version ${result.result.requiredFrontendVersion} failed to load, but you should be able to continue your work with the currently loaded version: ${version}`)                            
                    }
                    
                }
                else
                {
                    sessionStorage.removeItem('missedVersion')
                    sessionStorage.setItem('loadedVersion', version)
                }
                setConfig(result.result)
            }
            // get queue status
            {
                const qresult= await apiSelect(`/kpe20/queue`,'kpe20')
                if(qresult.success)
                    setQueueStatus(qresult.result)
            }
        }
       // if(!config || !config.UID===root || fakeUser )
            initRoot()
    },[root, fakeUser, updateConfig]);
  
    return (
        <KPE20Context.Provider value={{root,setRoot,config,setConfig,ws,setHandleMessage,userLang,setUserLang,langTexts, fakeUser, setFakeUser, queueStatus, setQueueStatus}}>
            <WsContext.Provider value={{wsStatus}}>
    {
      // this is allowing render of the childs  only after the config is loaded  
      config ?                                        // config available
      children : 
        config?.message === 'user not authorized' ?   // config true, but root could not be loaded
          <>
            <Menu attached color='blue' inverted style={{marginBottom:'20px'}}/>
            <Container textAlign='center'>
              {/*<Loader inverted active inline='centered'/>*/}
              <h1>{texts.notAuthorized}</h1>
            </Container>
          </>
        :
          <>
            <Menu attached color='blue' inverted style={{marginBottom:'20px'}}/>
            <Container textAlign='center'>
              {/*<Loader inverted active inline='centered'/>*/}
              <h1>{texts.loadingConfig}</h1>
            </Container>
          </>
    }
        </WsContext.Provider>
    </KPE20Context.Provider>

  )
}
export default KPE20ContextProvider



export const useRoot = () => 
{
  const result= useContext(KPE20Context)
  return [result.root,result.setRoot]
}
export const useLangTexts=()=>
{
    const result= useContext(KPE20Context)
    return result.langTexts ? result.langTexts : {}
}
export const useConfig = () => 
{
  const result= useContext(KPE20Context)
  return result.config
}

export const useSetConfig=() =>
{
  const result= useContext(KPE20Context)
  return result.setConfig
}

export const useWs =(cbData)=>
{
  const {root,ws,setQueueStatus,setFakeUser, fakeUser}= useContext(KPE20Context)
  useEffect(()=>{
    // set additionally the current root and setQueueSatatus as parameter to the ws
    ws.setCallback({...cbData, root,setQueueStatus, setFakeUser})
  },[cbData,root])

  return ws
}

export const useWsStatus=()=>
{
    return  useContext(WsContext).wsStatus
}

export const useQueueStatus=()=>
{
    return  useContext(KPE20Context).queueStatus
}

export const useLangtexts=()=>
{
    const {userLang,setUserLang}= useContext(KPE20Context)
    return {userLang,setUserLang}
}



export const useFakeUser=()=>
{
    const {fakeUser,setFakeUser}= useContext(KPE20Context)
    return {fakeUser,setFakeUser}
}

export const getEnvironment =() =>
{
    const UA=navigator.userAgent
    const IOS = UA.match(/iPhone|iPad|iPod/)
    const ANDROID = UA.match(/Android/)
    const standalone = window.matchMedia('(display-mode: standalone)').matches
    return {
      platform: IOS ? 'ios' : ANDROID ? 'android' : 'unknown',
      installed : !!(standalone || (IOS && !UA.match(/Safari/))),
      userAgent: UA,
    }
 }


