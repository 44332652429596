import React, {useState, useReducer, useEffect, useRef}  from 'react';
import {Container, Header, Button, Popup} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useWindowDimensions} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import MyTabulator, {autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import EditFunction from './EditFunction';

const Functions=(props)=> {

    const {functions,setFunctions,achievements,setModalParams,setContainerWidth}=props
    const [editUID,setEditUID]=useState(null)
    const [timestamp,setTimeStamp]=useState(0)
    const texts=useTexts('/functions')
    
    const {height}=useWindowDimensions()

    const cellClick=(cell)=>
    {
        const row=cell.getRow()
        console.log(row)
        const rowData=row.getData()
        setEditUID(rowData.UID) 
        setTimeStamp(Date.now())
    }

    useEffect(()=> {
        // have to place this call into an useEffect, as the react status is no availlable in a callback function of tabulator
        if(editUID)
        {
            setModalParams({
                addLevel:{
                    component:EditFunction,
                    editUID: editUID,
                    functions: functions,
                    setFunctions:setFunctions,
                    achievements:achievements,
                }
            })
        }
    },[editUID,timestamp])


    const addFunction=(ev)=>
    {
        setModalParams(
            {
                addLevel:{
                    component: EditFunction,
                    createNew:true,
                    functions: functions,
                    setFunctions:setFunctions,
                    achievements:achievements
                }
            }
        )
    }
    
    
    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, frozen:true}, //incrementing row number
        {title:  texts.fsDisplay, field: 'Display', width: 300,  headerFilter:"input"},
        { title:  texts.stage, field: 'stage', width: 200,  headerFilter:"input"},
    ]

    useEffect(
        ()=>{setContainerWidth(800)},
    [])

    return(
        <Container>
            <Header as="h2">{texts.fsHeader}</Header>
            <Popup
                    trigger={<Button 
                        circular 
                        icon='plus' 
                        basic
                        color='green' 
                        onClick={addFunction} 
                        size='mini'/>}
                    content={texts.addFunction}
                    style={{ position: 'absolute', right:'0px'}}
                    position='left center'
                    offset={[-40, 0]}
                />
            <MyTabulator
                index='UID'
                columns={listColumns}
                headerFilterPlaceholder={texts.headerFilterPlaceholder}
                data={functions}
                onClick={cellClick}
                compact="very"
                striped
                layout="fitDataStretch"
                replaceMode={true}
                height={Math.max(height-250,500)}  

            />
        </Container>
    )
}

export default Functions