import {Plugin} from "ckeditor5";

import PlaceholderEditing from "./placeholderediting";
import PlaceholderUI from "./placeholderUI";

export  class Placeholder extends Plugin {
  static get requires() {
    return [PlaceholderEditing, PlaceholderUI];
  }
}
