export const contextTexts=
{
    connectionErrorHeader: 'Ups...: Verbindungsfehler',
    connectionErrorText: '<ul><li>Entweder besteht keine Verbinung zum Internet</li><li>oder das Backend auf dem KPE Server hat ein Störung</li><li> oder der KPE Server ist nicht erreichbar</li></ul><h4>Wenn die Störung länger besteht, schreibe bitte an support@kpe.de</h4>',
    again: "Nochmals versuchen",
    webSocketError: "Die websocket Verbindung zum KPE-Datenbank Server konnte nicht hergestellt werden. Entweder ist der Datenbankserver nicht erreichbar oder es gibt Probleme mit deiner Netzwerkkonfiguration. Du verwendest z.B einen nicht websocket konformen web-proxy. Bitte wende Dich an den Administrator Deines lokalen Netzwerkes. Willst Du die Seite neu laden?",
    webSocketTimeout: "Der websocket hat länger nicht geantwortet. Ist Deine Internet Verbindung unterbrochen?",
    largeQueue: "Es sind im KPE Backend-System sehr viele Hintergrund Tasks aktiv. Dies kann z.B. bei Wartungsarbeiten der Fall sein. Man kann das System weiter benutzen. Es dauert aber länger, bis manche Aktualisierungen angezeigt werden. Sollte dieser Zustand länger als 8h anhalten, wende Dich bitte an support@kpe.de",
    Rails:
    {
        modalRailHeader: "Bearbeite Seitenspalten von:",
        leftRail: 'links',
        rightRail: "rechts",
        railType: "Typ",
        railGallery: "Bilder Gallerie",
        railHtml: "HTML Datei(en)",
        railInherit: "von Höherer Ebene erben (standard)",
        railOff: "keine",
        railGalleryUpload: "neues Bild hochladen",
        railReload: "nach dem ändern, bitte die Seite neu laden, damit die Änderung auch angezeigt wird",
       
    },
    fakeUser:{
        fakeUserTitle: 'Für Tests den angemeldeten User wechseln',
        fakeUserExplanation: 'testweise das api so umschalten, als ob man ein anderer user wäre. zurückwechseln geht über das profil Menu',
        userLabel: 'User wechseln',
        userPlaceholder: 'User wählen',
        confirmSave: 'Zeitweise  als anderer Benutzer testen? '
    },
    FilterElement:
    {
        valueIncluded:'aus',
        valueNotIncluded:'nicht in',
        valueNone:'nichts',
        person_none:'niemand',
        person_all:'jeder',
        person_stage :'Stufe',
        person_hierarchie :'Ebene',
        person_gender:'Geschlecht',
        person_age:'Alter',
        person_birthday: 'Geburtstag',
        person_birthdayMonth: 'Geburtstag Monat',
        person_openBills: 'offene Rechnungen',
        person_family:'# Familie',
        person_achievement: 'Ernennung',
        job_none:'niemand',
        job_all:'alle',
        job_gender:'Person Geschlecht',
        job_stage:'Person Stufe',
        job_age:'Person Alter',
        job_birthday: 'Geburtstag',
        job_birthdayMonth: 'Geburtstag Monat',
        job_openBills: 'offene Rechnungen',
        job_family:'Person # Familie',
        job_achievement: 'Ernennung',
        job_hierarchie: 'Person Ebene',
        job_groupGender:'Amt Gruppe Geschlecht',
        job_groupHierarchie:'Amt Gruppe Ebene',
        job_groupStage:'Amt Gruppe Stufe',
        job_vote:'Amt stimmber. Ebene', 
        job_functionStage:'Amt Stufe',
        job_functionHierarchie: 'Amt Ebenen',
        job_function:'Amt/Funktion',
        job_categories: 'Amt Kategorien',
        job_qualified:'Ernannt',
        group_none:'keine Gruppe',
        group_all:'alle Gruppen',
        group_groupGender:'Gruppe Geschlecht',
        group_groupHierarchie:'Gruppe Ebene',
        group_groupStage:'Gruppe Stufe',
        group_groupCount: 'Gruppe # Mitglieder',
        group_stage: 'obsolet stage Tag',
        group_gender: 'obsolete gender Tag',
        group_hierarchie: 'obsolete hierarchie tag',
        extern_none:'niemand',
        extern_all:'jeder',
        extern_hierarchie :'Ebene',
        extern_gender:'Geschlecht',
        extern_age:'Alter',
        extern_birthday: 'Geburtstag',
        extern_birthdayMonth: 'Geburtstag Monat',
        extern_openBills: 'offene Rechnungen',
        extern_family:'# Familie',
        extern_category: 'extern Kategorie',      

        tagType:'Typ',
        placeholderTag:'⬜',
        lAnd:'und',
        lOr:'oder',
        lNand:'nicht und',
        lNor:'nicht oder',
        lXor:'entweder oder',
        today: 'heute',
        yesterday: 'gestern',
        tomorrow: 'morgen',
        lastWeek: 'letzteWoche',
        thisWeek: 'diese Woche',
        nextWeek: 'nächste Woche',
        thisMonth: 'diesen Monat',
        January: 'Januar',
        February: 'Februar',
        March: 'März',
        April: 'April',
        May: 'Mai',
        June: 'Juni',
        July: 'Juli',
        August: 'August',
        September: 'September',
        October: 'Oktober',
        November: 'November',
        December: 'December'
    },
    Settings:{
        Settings:'Einstellungen',
        reset:'Einstellungen zurücksetzen',
        Language:'Sprache',
        LanguageDescr:'Anzeigesprache auswählen',
        languageDisclaimer:'Die meisten Übersetzungen der Sprachdateien wurden vorwiegend maschinell mittels https://translate.google.com erstellt',
        Theme:'Anzeigestil',
        ThemeDescr:'Den Farbmodus der App auswählen',
        light:'hell',
        dark:'dunkel',
        system:'System',
        themeDisclaimer:'Beim Umschalten kann es zu Anzeigefehlern kommen. Nach dem Speichern und Neuladen der Seite sind die Fehler dann weg.',
        resetSettings: 'Einstellungen zurücksetzen'
    },
    Languages:
    {
        german:'Deutsch',
        english:'Englisch',
        latin:'Latein',
        french:'Französich',
        danish:'Dänisch',
        dutch:'Niederländisch',
        italien:'Italienisch',
        polish:'Polnisch',
        spanish:'Spanisch',
        italian: 'Italienisch'
        
    },
    Menu:
    {
        Profile:'Profil',
        DevelopmentInfos: 'Entwickler Board',
        Settings:'Einstellungen',
        Dashboard:'Dashboard',
        Home:'Home',
        changeOrga:'Orga wechseln',
        fakeUser: 'User Wechseln',
        Configs:'Server Konfiguration',
        connectionStatus:'Status der Server Verbindung',
        versionHistory:'Versionshistorie',
        queueIsProcessing: 'Der Server führt gerade im Hintergrund Aktualisierungen durch',
        adminApp: "Admin"


    },
    FileEditor:
    {
        FileEditor:'Bearbeite File:',
        errorsDetected:'Es gibt Fehler in dem File, speichern verweigert',

    },
    SelectOrga:
    {
        headerOrga: "Einen anderen Bund wählen",
        orgaLable: "Verfügbare Organisationen"
    },
    Changelog:
    {
        headerText: 'Versionshitorie',
        version: 'Version:'
    }


}