import React, { useEffect,  isValidElement, cloneElement, useState } from 'react'
import {Button, Container, Divider, Form, Header, Loader, Popup, Segment} from 'semantic-ui-react'
import useTexts from '../useTexts'
import {useApi} from  '@wollo-lib/kpe-context'    // './kpe-context/src/index.js'
import {useConfig,useSetConfig,useUserLang} from '../KPE20context'
import { useHistory } from 'react-router'

import {app} from '../../config'



const Settings=(props) => {

    const { children} = props


    const [languages,setLanguages]=useState([])
    const [defaultSettings,setDefaultSettings]= useState({
        language:       'german',
        theme:          'system',
    })
  
    const texts={...useTexts('lib20Texts','Settings'),...useTexts('lib20Texts','Languages')}

    
    const {apiSelect, apiUpdate}=useApi()
    const config=useConfig()
    const setConfig=useSetConfig()
    const history=useHistory()


    const UID = config.orgaUser.UID

    
    const sortLanguages = (langs) => {
        let langBuffer = []
        langs.forEach(lang => langBuffer.push({'lang': lang,'text':texts[lang]}))
        langBuffer.sort((a,b) =>  {
            const aText = a.text.toLowerCase()
            const bText = b.text.toLowerCase()
            return (aText === bText ? 0 : aText < bText ? -1 : 1)
        })
        console.log(langBuffer);
        let langSorted = []
        langBuffer.forEach(o => langSorted.push(o.lang))
        setLanguages(langSorted)
        // console.log(languages)
    }

    useEffect(()=>{

        const getLanguages=async ()=>
        {
            const result=await apiSelect(`/kpe20/languages/${app}`)
            if(result.success) {
                setLanguages(result.files.map(f=>f.name.replace('languages/','').replace('.json','')))

                // sortLanguages(result.files.map(f=>f.name.replace('languages/','').replace('.json','')))
            }
        }
        getLanguages()
    },[])




    useEffect(()=>{
        if(!config.orgaUser.Data.settings)
            reset()
    }, [config])

    const handleChange = (ev, {name, value}) => {
        setConfig(old=>({...old,orgaUser:{...old.orgaUser,Data:{...old.orgaUser.Data,settings:{...old.orgaUser.Data.settings,[name]:value}}}}))
        
        if (value === 'Openstreetmap') {
            setConfig(old=>({...old,orgaUser:{...old.orgaUser,Data:{...old.orgaUser.Data,settings:{...old.orgaUser.Data.settings,mapLinkType:'Address'}}}}))
        }
    }

    const submit = async (e) => {
        const result = await apiUpdate('/kpe20/person/'+UID,{settings:config.orgaUser.Data.settings})
        if(result.success)
        {   
            window.location.reload()
            history.goBack()
        }
    }

    const reset = async (e) => {
        setConfig(old=>({...old,orgaUser:{...old.orgaUser,Data:{...old.orgaUser.Data,settings:defaultSettings}}}))
        await apiUpdate('/kpe20/person/'+UID,{settings:defaultSettings})
    }

    return(
        <Container as={Segment}>

            <Segment raised >
                <Header align='center' as='h1' >{texts.Settings}</Header>
            </Segment>

            <Header>{texts.Language}</Header>
            {texts.LanguageDescr}
            <Form.Group>
                {languages.length === 0 
                ? <Loader active inline size='small'/>
                : languages.map((language, index) => (
                    <Form.Radio 
                        name='language'
                        key={'set'+language}
                        label={texts[language]}
                        value={language}
                        checked={config.orgaUser.Data.settings?.language === language}
                        onChange={handleChange}
                    />
                ))}
            </Form.Group>
            <p>{texts.languageDisclaimer}</p>

            <br/><Divider/>
            <Header>{texts.Theme}</Header>
            {texts.ThemeDescr}
            <Form.Group>
                {   ['light', 'dark', 'system'].map((theme, index) => (
                    <Form.Radio 
                        name='theme'
                        key={index}
                        label={texts[theme]}
                        value={theme}
                        checked={config.orgaUser.Data.settings?.theme === theme}
                        onChange={handleChange}
                    />
                ))}
            </Form.Group>
            <p>{texts.themeDisclaimer}</p>

            <br/><Divider/>

          

            {
                Array.isArray(children) ?
                children.map(child=>
                    isValidElement(child) ? cloneElement(child,{handleChange,setDefaultSettings}) : child
                 )
                 :
                    isValidElement(children) ? cloneElement(children,{handleChange,setDefaultSettings}) : children

            }
            <br/>
            <Button 
                content={texts.save} 
                positive
                onClick={submit}
            />
            <Button 
                content={texts.resetSettings}
                // primary
                disabled={!config.orgaUser.Data.settings || config.orgaUser.Data.settings === defaultSettings}
                onClick={reset}
            />

        </Container>
    )
}

export default Settings
