import React,{ useState, useEffect, useRef}  from 'react';
import { Menu,Icon,Segment} from 'semantic-ui-react'
import useTexts from '../useTexts'
import ButtonSelect from './ButtonSelect'
import {useConfig} from '../KPE20context'


const ConditionalElement=({value,para,setPara,tag,setTag,oper,setOper,type,removeEntry, functions,achievements, name, addLevel, logTags,isVisibility})=>
{

    const texts=useTexts('lib20Texts','FilterElement')
    const config=useConfig()
    const opNull={
        NN:texts.valueNone
    }

    const opBool= {
        EQ: '=',
    }

    const opAlpha= {
        EQ: '=',
        NE : '<>',
        IN : texts.valueIncluded,
        NI : texts.valueNotIncluded
    }

  
    
    const opNumeric= {
        EQ: '=',
        NE : '<>',
        GT :  '>',
        GE : "\u2265",
        LT : '<',
        LE : "\u2264",
        IN : texts.valueIncluded,
        NI : texts.valueNotIncluded
    }

    const opNumericInverted= {
        EQ: '=',
        NE : '<>',
        GT :  '<',
        GE : "\u2264",
        LT : '>',
        LE : "\u2265",
        IN : texts.valueIncluded,
        NI : texts.valueNotIncluded
    }



     // defines the parameters to be filtered for and the type of possible operations
     const tagArray={
        person: {
                    hierarchie: opNumericInverted,
                    stage: opNumeric,
                    gender: opAlpha,
                    age: opNumeric, 
                    birthday: opBool,
                    birthdayMonth: opAlpha,
                    openBills: opBool,
                    family: opNumeric,
                    achievement: opAlpha,
                    none: opNull,
                    all: opNull,

                },
        extern: {
                    hierarchie: opNumericInverted,
                    age: opNumeric, 
                    birthday: opBool,
                    birthdayMonth: opAlpha,
                    openBills: opBool,
                    family: opNumeric,
                    category: opAlpha,
                    none: opNull,
                    all: opNull,

                },
        job:    {
                    hierarchie: opNumericInverted,
                    stage: opNumeric,
                    gender: opAlpha,
                    age: opNumeric,
                    birthday: opBool,
                    birthdayMonth: opAlpha, 
                    openBills: opBool,
                    family: opNumeric,
                    achievement: opAlpha,
                    groupHierarchie: opNumericInverted,
                    groupStage: opNumeric,
                    groupGender: opAlpha,
                    functionHierarchie: opAlpha,
                    functionStage: opNumeric,
                    function: opAlpha,
                    vote: opBool,
                    qualified: opBool,
                    categories: opAlpha,
                    none: opNull,
                    all: opNull,

                },
        group:  {
                    groupHierarchie: opNumericInverted,
                    groupStage: opNumeric,
                    groupGender: opAlpha,
                    hierarchie: opNumericInverted,
                    stage: opNumeric,
                    gender: opAlpha,
                    groupCount: opNumeric,
                    none: opNull,
                    all: opNull,

                },
      
    }
    tagArray.guest=tagArray.person
    
    if(!value || ( typeof type==='string' && (tag  && !tagArray[type][tag]) && tag!=='none'  && tag!=='all'))
    {
        if(tag && tag !=='none' && tag !=='all')
            console.log('invalid tag',type,tag)
        return ''
    }

    const paras= ! config.UID ? {}: {
        groupStage: config.Stage.map((stage,Index)=>({value:Index,key:Index, text:config.Stage[Index]})),
        stage: config.Stage.filter((stage,Index)=>(Index>0)).
                    map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),
        hierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
                        map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        gender: Object.keys(config.Gender).map(key=>({value:key, key: key,text:config.Gender[key] })),
        age: Array(110).fill(0,0,110).map((age,Index)=>({value:Index,key:Index, text: Index})),
        family: Array(15).fill(0,0,15).map((family,Index)=>({value:Index,key:Index, text: Index})),
        openBills: [{key:1,value:1,text:texts.yes},{key:0,value:0,text:texts.no}],
        function: functions ? functions.map((el)=>({key:el.UID,value:el.UID, text:el.Display})) :  {},
        personGender:  Object.keys(config.Gender).map(key=>({value:key, key: key,text:config.Gender[key] })),
        groupGender:  Object.keys(config.GenderGroups).map(key=>({value:key, key: key,text:config.GenderGroups[key] })),
        groupHierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        groupStage:  config.Stage.filter((stage,Index)=>(Index>0)).
            map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),
        groupCount:  Array(200).fill(0,0,200).map((age,Index)=>({value:Index,key:Index, text: Index})),            
        functionHierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
       /* functionStage:  config.Stage.filter((stage,Index)=>(Index>0)).
            map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),*/
        functionStage:  config.Stage.
            map((stage,Index)=>({value:Index,key:Index, text:config.Stage[Index]})),
        qualified: [{key:1,value:1,text:texts.yes},{key:0,value:0,text:texts.no}],
        vote: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        // job categories
        categories: config.FunctionCategories.
            map((c,Index)=>({key:Index,value:c,text:c})),
        // extern gategory
        category: Object.entries(config.ExternCategories).
            map(([key,value])=>({key:key,value:key,text:value})),
        achievement: achievements ? achievements.map((a,Index)=>({key:Index, value:a.UID,text:a.Display})) :{},
        eventGender:  Object.keys(config.GenderGroups).map(key=>({value:key, key: key,text:config.GenderGroups[key] })),
        eventHierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        eventStage:  config.Stage.filter((stage,Index)=>(Index>0)).
            map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),
    }

    // add extra time dynamic filters
    if(config.UID && !isVisibility)
    {
        paras.birthday= [
            {key:0,value:255,text:texts.today},
            {key:-1,value:-1,text:texts.yesterday},
            {key:1,value:1,text:texts.tomorrow},
            {key:-7,value:-7,text:texts.lastWeek},
            {key:7,value:7,text:texts.thisWeek},
            {key:14,value:14,text:texts.nextWeek},
        ]
        paras.birthdayMonth= [
                {key:255,value:255,text:texts.thisMonth},
                {key:1,value:1,text:texts.January},
                {key:2,value:2,text:texts.February},
                {key:3,value:3,text:texts.March},
                {key:4,value:4,text:texts.April},
                {key:5,value:5,text:texts.May},
                {key:6,value:6,text:texts.June},
                {key:7,value:7,text:texts.July},
                {key:8,value:8,text:texts.August},
                {key:9,value:9,text:texts.September},
                {key:10,value:10,text:texts.October},
                {key:11,value:11,text:texts.November},
                {key:12,value:12,text:texts.December},
        ]

    }

    const tagOptions= 
        typeof type==='string' ?
            Object.entries(tagArray[type]).map(([key,oper])=>({value:key,text:texts[type+'_'+key], key:key, oper:oper}))
        :   // type is an array, so we have to merge the options of different types
            type.reduce((options,aType)=>{
                const add=Object.entries(tagArray[aType]).reduce((toBeAdded,[key,oper])=>
                {
                    if(!options.some(o=>o.value===key))
                    {
                        return [...toBeAdded,{value:key,text:texts[aType+'_'+key], key:key,oper:oper }]
                    }
                    else
                    {
                        return toBeAdded
                    }
                },[])
                return [...options,...add]
            },[])
    

    //const operOptions= (tag || tag===0) && tag!=='none' && tag!=='all' ? Object.keys(tagArray[type][tag]).map(key=>({key:key,value:key,text:tagArray[type][tag][key]})) : []
    const myTag=tagOptions.find(o=>o.value===tag)
    const operOptions= (tag || tag===0) && tag!=='none' && tag!=='all' && myTag ? Object.entries(myTag.oper).map(([oper,text])=>({key:oper,value:oper,text:text})) : []
    const paraOptions=  (tag || tag===0)  ? (tag!=='none' && tag!=='all' ?  paras[tag] :null) : []

    const handleChange=(ev,{name,value})=>
    {
        if(name==='tag')
        {
            setTag(value)
            setOper('')
            setPara('')
        }
        if(name==='oper')
        {
            setOper(value)
            if(value==='IN' || value==='NI')
            {
                setPara([])
            }
            else
            {
                setPara(null)
            }
        }
        if(name==='para')
            setPara(value)
    }


    return  (        
    <Segment basic compact style={{padding:'0'}} size='small'>
        <ButtonSelect
            name='tag'
            onChange={handleChange}
            value={tag}
            options={tagOptions}
            placeholder={texts.placeholderTag}
        />
        {
            !tag  ? '' :

            
            tag==='none' || tag==='all' ? '':
            <>
                <ButtonSelect
                    name='oper'
                    onChange={handleChange}
                    value={oper}
                    options={operOptions}
                    placeholder="&#x2B1C;"
                    attach right left
                />
                {
                    !oper ? '':
                    <ButtonSelect
                        name='para'
                        onChange={handleChange}
                        value={para}
                        options={paraOptions}
                        placeholder="&#x2B1C;"
                        attach left
                        multiple={Array.isArray(para)}
                    />
                }
            </>
            
        }
        {
           /* Object.keys(value).length===0 ||*/!removeEntry ? '' :
                 <Icon name='trash'  size='small' color='red' onClick={()=>removeEntry(name)}/>


        }
        {
            tag &&  ((tag==='none' || tag==='all') || (oper && para)) ?
            <ButtonSelect
                name='tag'
                onChange={addLevel}
                options={logTags}
                placeholder={texts.placeholderTag}
            />
            :
            ''
        }
        
    </Segment>
    
    )
}


export default ConditionalElement